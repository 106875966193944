import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  X, Search, Plus, Mail, DollarSign, Clock,
  MapPin, Building, Calendar, Save, Briefcase,
  ChevronDown, Trash2, Edit, Users, User, Phone, Download, Link
} from 'lucide-react';

const jobTypes = [
  'Full-time',
  'Part-time',
  'Contract',
  'Remote',
  'Internship'
];

const applicationStatusColors = {
    'Pending': 'bg-yellow-500/10 text-yellow-400',
    'Reviewed': 'bg-blue-500/10 text-blue-400',
    'Interviewed': 'bg-purple-500/10 text-purple-400',
    'Accepted': 'bg-green-500/10 text-green-400',
    'Rejected': 'bg-red-500/10 text-red-400'
  };

  const applicationStatuses = [
    'Pending',
    'Reviewed',
    'Interviewed',
    'Accepted',
    'Rejected'
  ];

  const ApplicationModal = ({ application, onClose, onUpdateStatus, job, onDeleteApplication }) => {
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);

    const handleStatusChange = async (newStatus) => {
      try {
        await onUpdateStatus(application.id, newStatus);
        setShowStatusDropdown(false);
      } catch (error) {
        console.error('Error updating status:', error);
      }
    };

    const handleDownloadResume = () => {
        // Create a downloadable link from base64
        if (application.resume_base64) {
          const linkSource = `data:application/pdf;base64,${application.resume_base64}`;
          const downloadLink = document.createElement('a');
          const fileName = `${application.full_name}_resume.pdf`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      };

      const handleDeleteApplication = () => {
        // Show confirmation dialog
        if (window.confirm(`Are you sure you want to delete the application for ${application.full_name}?`)) {
          onDeleteApplication(application.id);
          onClose(); // Close the modal after deletion
        }
      };

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          className="bg-gray-900 rounded-2xl p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto"
          onClick={e => e.stopPropagation()}
        >
          {/* Header */}
          <div className="flex justify-between items-start mb-6">
            <div>
              <h2 className="text-2xl font-bold mb-2">{application.full_name}</h2>
              <div className="flex items-center gap-4">
                <div className="relative">
                  <button
                    onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                    className={`inline-flex items-center gap-2 px-3 py-1 rounded-full transition-colors ${applicationStatusColors[application.status]}`}
                  >
                    {application.status}
                    <ChevronDown className="w-4 h-4" />
                  </button>

                  <div className="flex items-center gap-2">
                        <button
                        onClick={handleDeleteApplication}
                        className="p-2 hover:bg-red-500/10 text-red-400 rounded-lg transition-colors"
                        title="Delete Application"
                        >
                        <Trash2 className="w-5 h-5" />
                        </button>
                    </div>

                  <AnimatePresence>
                    {showStatusDropdown && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="absolute top-full left-0 mt-2 bg-gray-800 rounded-lg shadow-lg overflow-hidden z-50"
                      >
                        {applicationStatuses.map(status => (
                          <button
                            key={status}
                            onClick={() => handleStatusChange(status)}
                            className={`w-full px-4 py-2 text-left hover:bg-gray-700 transition-colors ${
                              status === application.status ? 'bg-gray-700' : ''
                            }`}
                          >
                            {status}
                          </button>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                <span className="text-sm text-gray-400">
                  Applied {new Date(application.created_at).toLocaleDateString()}
                </span>
              </div>
            </div>
          </div>

          {/* Application Details */}
          <div className="space-y-6">
            {/* Position Applied For */}
            <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
              <h3 className="text-lg font-medium mb-2">Position Applied For</h3>
              <div className="flex items-center gap-2">
                <Briefcase className="w-5 h-5 text-gray-400" />
                <span className="text-gray-300">{job.title}</span>
              </div>
            </div>

            {/* Contact Information */}
            <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
              <h3 className="text-lg font-medium mb-4">Contact Information</h3>
              <div className="space-y-3">
                <div className="flex items-center gap-3">
                  <Mail className="w-5 h-5 text-gray-400" />
                  <a href={`mailto:${application.email}`} className="text-blue-400 hover:underline">
                    {application.email}
                  </a>
                </div>
                <div className="flex items-center gap-3">
                  <Phone className="w-5 h-5 text-gray-400" />
                  <a href={`tel:${application.phone}`} className="text-blue-400 hover:underline">
                    {application.phone}
                  </a>
                </div>
              </div>
            </div>

            {/* Professional Details */}
            <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
              <h3 className="text-lg font-medium mb-4">Professional Details</h3>
              <div className="space-y-3">
                <div className="flex items-center gap-3">
                  <Clock className="w-5 h-5 text-gray-400" />
                  <span className="text-gray-300">{application.experience} years experience</span>
                </div>
                {application.linkedin_url && (
                  <div className="flex items-center gap-3">
                    <Link className="w-5 h-5 text-gray-400" />
                    <a
                      href={application.linkedin_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:underline"
                    >
                      LinkedIn Profile
                    </a>
                  </div>
                )}
                {application.portfolio_url && (
                  <div className="flex items-center gap-3">
                    <Link className="w-5 h-5 text-gray-400" />
                    <a
                      href={application.portfolio_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:underline"
                    >
                      Portfolio Website
                    </a>
                  </div>
                )}
              </div>
            </div>

            {/* Resume & Cover Letter */}
            <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
              <h3 className="text-lg font-medium mb-4">Documents</h3>
              {application.resume_base64 && (
                <button
                onClick={handleDownloadResume}
                className="flex items-center gap-2 text-blue-400 hover:underline"
                >
                <Download className="w-5 h-5" />
                Download Resume
                </button>
                )}
              {application.cover_letter && (
                <div>
                  <h4 className="text-sm font-medium text-gray-400 mb-2">Cover Letter</h4>
                  <p className="text-gray-300 whitespace-pre-line">{application.cover_letter}</p>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </motion.div>
    );
  };

  const JobApplications = () => {
    const [applications, setApplications] = useState([]);
    const [jobs, setJobs] = useState({});  // Store jobs by their ID
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
          setLoading(true);
          // Fetch applications
          setLoading(true);

          // First, fetch all jobs
          const jobsResponse = await fetch('https://www.phynux.tech/api/jobs');
          const jobsData = await jobsResponse.json();
          console.log('Jobs:', jobsData);

          // Fetch applications for each job
          const allApplications = [];
          for (const job of jobsData) {
            const applicationsResponse = await fetch(`https://www.phynux.tech/api/jobs/${job.id}/applications`);
            const applicationsData = await applicationsResponse.json();

            allApplications.push(...applicationsData);
          }
          setApplications(allApplications);
          setJobs(jobsData);
        } catch (err) {
          setError('Failed to load applications');
          console.error('Error loading data:', err);
        } finally {
          setLoading(false);
        }
      };

      const handleDeleteApplication = async (applicationId) => {
        try {
          const response = await fetch(`https://www.phynux.tech/api/applications/${applicationId}`, {
            method: 'DELETE',
          });

          if (!response.ok) {
            throw new Error('Failed to delete application');
          }

          // Remove the deleted application from the state
          setApplications(applications.filter(app => app.id !== applicationId));
        } catch (error) {
          console.error('Error deleting application:', error);
          // Optionally, show an error message to the user
        }
      };

      const selectApplication = (application) => {
        console.log('All Jobs:', jobs);
        console.log('Current Application:', application);

        let job = null;

        // Try to find the job by job_id
        if (application.id) {
          job = jobs.find(j => j.id === application.job_id);
          console.log('Job found by job_id:', job);
        }

        // If no job found, try matching by id
        if (!job) {
          job = jobs.find(j => j.id === application.job_id);
          console.log('Job found by application id:', job);
        }

        if (!job) {
          console.error('No job found for application', application);
          return;
        }

        setSelectedApplication({
          ...application,
          job: job
        });
      };

    const handleUpdateStatus = async (applicationId, newStatus) => {
      try {
        const response = await fetch(`https://www.phynux.tech/api/applications/${applicationId}/status`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status: newStatus }),
        });

        if (!response.ok) throw new Error('Failed to update status');

        // Update local state
        setApplications(applications.map(app =>
          app.id === applicationId ? { ...app, status: newStatus } : app
        ));

        // Update selected application if open
        if (selectedApplication?.id === applicationId) {
          setSelectedApplication(prev => ({ ...prev, status: newStatus }));
        }
      } catch (error) {
        console.error('Error updating application status:', error);
      }
    };

    const filteredApplications = applications.filter(app => {
      const matchesSearch = app.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                           app.email.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesStatus = statusFilter === 'All' || app.status === statusFilter;
      return matchesSearch && matchesStatus;
    });

    if (loading) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      );
    }

    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-2xl font-bold">Job Applications</h2>
          <div className="flex items-center gap-4">
            {/* Status Filter */}
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="All">All Status</option>
              {applicationStatuses.map(status => (
                <option key={status} value={status}>{status}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Search Bar */}
        <div className="relative max-w-xl mb-6">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search by name or email..."
            className="w-full bg-black/50 border border-gray-800 rounded-lg pl-10 pr-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
          />
          <Search className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
        </div>

        {/* Applications List */}
        <div className="space-y-4">
        {filteredApplications.map((application) => (
            <motion.div
            key={application.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-4 border border-gray-800 cursor-pointer hover:border-blue-500/20 transition-all"
            onClick={() => selectApplication(application)}  // Change this line
            >
            <div className="flex justify-between items-start">
                <div>
                <h3 className="font-semibold text-lg mb-1">{application.full_name}</h3>
                <div className="flex items-center gap-4 text-sm text-gray-400">
                    <span className="flex items-center gap-1">
                    <Mail className="w-4 h-4" />
                    {application.email}
                    </span>
                    {/* Add job title here */}
                    <span className="flex items-center gap-1">
                    <Briefcase className="w-4 h-4" />
                    {jobs[application.id]?.title || 'Unknown Position'}
                    </span>
                    <span className="flex items-center gap-1">
                    <Calendar className="w-4 h-4" />
                    {new Date(application.created_at).toLocaleDateString()}
                    </span>
                </div>
                </div>
                <div className={`px-3 py-1 rounded-full ${applicationStatusColors[application.status]}`}>
                {application.status}
                </div>
            </div>
            </motion.div>
        ))}

          {filteredApplications.length === 0 && (
            <div className="text-center py-12 text-gray-400">
              <User className="w-12 h-12 mx-auto mb-4" />
              <p className="text-lg">No applications found</p>
              <p className="text-sm mt-2">
                {searchQuery || statusFilter !== 'All' ? 'Try adjusting your filters' : 'No applications received yet'}
              </p>
            </div>
          )}
        </div>

        {/* Application Modal */}
        <AnimatePresence>
        {selectedApplication && (
        <ApplicationModal
            application={selectedApplication}
            job={selectedApplication.job}
            onClose={() => setSelectedApplication(null)}
            onUpdateStatus={handleUpdateStatus}
            onDeleteApplication={handleDeleteApplication}  // Pass the delete handler
        />
        )}
    </AnimatePresence>
      </div>
    );
  };

const NewJobForm = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    location: '',
    type: '',
    salary: '',
    description: '',
    requirements: '',
    status: 'Active'
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const response = await fetch('https://www.phynux.tech/api/jobs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          requirements: formData.requirements.split('\n').filter(req => req.trim())
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to create job listing');
      }

      onSuccess(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="max-w-4xl mx-auto">
      {error && (
        <div className="bg-red-500/20 text-red-400 px-4 py-3 rounded-lg mb-6">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Job Title */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Job Title *
            </label>
            <input
              type="text"
              name="title"
              required
              value={formData.title}
              onChange={handleChange}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          {/* Job Type */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Job Type *
            </label>
            <select
              name="type"
              required
              value={formData.type}
              onChange={handleChange}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="">Select type</option>
              {jobTypes.map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          {/* Location */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Location *
            </label>
            <input
              type="text"
              name="location"
              required
              value={formData.location}
              onChange={handleChange}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          {/* Salary */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Salary *
            </label>
            <input
              type="text"
              name="salary"
              required
              value={formData.salary}
              onChange={handleChange}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="e.g., $50,000 - $70,000/year"
            />
          </div>

          {/* Status */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Status
            </label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="Active">Active</option>
              <option value="Draft">Draft</option>
              <option value="Closed">Closed</option>
            </select>
          </div>
        </div>

        {/* Description */}
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Job Description *
          </label>
          <textarea
            name="description"
            required
            value={formData.description}
            onChange={handleChange}
            rows={4}
            className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Describe the role, responsibilities, and what you're looking for..."
          />
        </div>

        {/* Requirements */}
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Requirements *
          </label>
          <textarea
            name="requirements"
            required
            value={formData.requirements}
            onChange={handleChange}
            rows={4}
            className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Enter each requirement on a new line..."
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className="inline-flex items-center gap-2 px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors disabled:opacity-50"
          >
            {loading ? (
              <>
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                Creating Job...
              </>
            ) : (
              <>
                <Save className="w-5 h-5" />
                Create Job Listing
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

const JobModal = ({ job, onClose, onUpdateStatus, onDelete, onEdit }) => {
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);

  const handleStatusChange = async (newStatus) => {
    try {
      await onUpdateStatus(job.id, newStatus);
      setShowStatusDropdown(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-gray-900 rounded-xl p-6 max-w-lg w-full shadow-xl"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-start mb-6">
          <div>
            <h2 className="text-2xl font-bold mb-2">{job.title}</h2>
            <div className="relative">
              <button
                onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                className={`inline-flex items-center gap-2 px-3 py-1 rounded-full transition-colors ${
                  job.status === 'Active' ? 'bg-green-500/10 text-green-400' :
                  job.status === 'Draft' ? 'bg-yellow-500/10 text-yellow-400' :
                  'bg-red-500/10 text-red-400'
                }`}
              >
                {job.status}
                <ChevronDown className="w-4 h-4" />
              </button>

              <AnimatePresence>
                {showStatusDropdown && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="absolute top-full left-0 mt-2 bg-gray-800 rounded-lg shadow-lg overflow-hidden z-50"
                  >
                    {['Active', 'Draft', 'Closed'].map(status => (
                      <button
                        key={status}
                        onClick={() => handleStatusChange(status)}
                        className={`w-full px-4 py-2 text-left hover:bg-gray-700 transition-colors ${
                          status === job.status ? 'bg-gray-700' : ''
                        }`}
                      >
                        {status}
                      </button>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={() => onEdit(job)}
              className="p-2 hover:bg-blue-500/10 text-blue-400 rounded-lg transition-colors"
              title="Edit job"
            >
              <Edit className="w-5 h-5" />
            </button>
            <button
              onClick={() => {
                if (window.confirm('Are you sure you want to delete this job listing?')) {
                  onDelete(job.id);
                }
              }}
              className="p-2 hover:bg-red-500/10 text-red-400 rounded-lg transition-colors"
              title="Delete job"
            >
              <Trash2 className="w-5 h-5" />
            </button>
            <button
              onClick={onClose}
              className="p-2 hover:bg-white/5 rounded-lg transition-colors"
              title="Close modal"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-4">
              <div className="flex items-center gap-3 text-gray-300">
                <MapPin className="w-5 h-5" />
                <div>
                  <p className="text-sm text-gray-400">Location</p>
                  <p>{job.location || 'N/A'}</p>
                </div>
              </div>
              <div className="flex items-center gap-3 text-gray-300">
                <Briefcase className="w-5 h-5" />
                <div>
                  <p className="text-sm text-gray-400">Type</p>
                  <p>{job.type}</p>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center gap-3 text-gray-300">
                <DollarSign className="w-5 h-5" />
                <div>
                  <p className="text-sm text-gray-400">Salary</p>
                  <p>{job.salary}</p>
                </div>
              </div>
              <div className="flex items-center gap-3 text-gray-300">
                <Clock className="w-5 h-5" />
                <div>
                  <p className="text-sm text-gray-400">Posted</p>
                  <p>{new Date(job.created_at).toLocaleDateString()}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-4 border-t border-gray-800">
            <h3 className="text-lg font-medium mb-3">Description</h3>
            <p className="text-gray-300 whitespace-pre-line">{job.description}</p>
          </div>

          <div className="pt-4 border-t border-gray-800">
            <h3 className="text-lg font-medium mb-3">Requirements</h3>
            <ul className="list-disc pl-5 space-y-2">
              {job.requirements.map((requirement, index) => (
                <li key={index} className="text-gray-300">{requirement}</li>
              ))}
            </ul>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const EditJobForm = ({ job, onClose, onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
      title: '',
      location: '',
      type: '',
      salary: '',
      description: '',
      requirements: '',
      status: 'Active'
    });

    useEffect(() => {
      if (job) {
        setFormData({
          ...job,
          requirements: job.requirements.join('\n')
        });
      }
    }, [job]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setError(null);
      setLoading(true);

      try {
        const response = await fetch(`https://www.phynux.tech/api/jobs/${job.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...formData,
            requirements: formData.requirements.split('\n').filter(req => req.trim())
          }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Failed to update job listing');
        }

        onSuccess(data);
        onClose();
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
        <div className="bg-gray-900 rounded-xl p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Edit Job Listing</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-white/5 rounded-lg transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {error && (
            <div className="bg-red-500/20 text-red-400 px-4 py-3 rounded-lg mb-6">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Job Title */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Job Title *
                </label>
                <input
                  type="text"
                  name="title"
                  required
                  value={formData.title}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              {/* Job Type */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Job Type *
                </label>
                <select
                  name="type"
                  required
                  value={formData.type}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="">Select type</option>
                  {jobTypes.map(type => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              {/* Location */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Location *
                </label>
                <input
                  type="text"
                  name="location"
                  required
                  value={formData.location}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              {/* Salary */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Salary *
                </label>
                <input
                  type="text"
                  name="salary"
                  required
                  value={formData.salary}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              {/* Status */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Status
                </label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="Active">Active</option>
                  <option value="Draft">Draft</option>
                  <option value="Closed">Closed</option>
                </select>
              </div>
            </div>

            {/* Description */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Job Description *
              </label>
              <textarea
                name="description"
                required
                value={formData.description}
                onChange={handleChange}
                rows={4}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Requirements */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Requirements *
              </label>
              <textarea
                name="requirements"
                required
                value={formData.requirements}
                onChange={handleChange}
                rows={4}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Enter each requirement on a new line..."
              />
            </div>

            {/* Submit Buttons */}
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="px-6 py-3 bg-gray-800 hover:bg-gray-700 rounded-lg transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="inline-flex items-center gap-2 px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors disabled:opacity-50"
              >
                {loading ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    Updating...
                  </>
                ) : (
                  <>
                    <Save className="w-5 h-5" />
                    Update Job Listing
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const JobsList = () => {
    const [activeTab, setActiveTab] = useState('list');
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [editingJob, setEditingJob] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetchJobs();
    }, []);

    const fetchJobs = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://www.phynux.tech/api/jobs');
        const data = await response.json();
        setJobs(data);
      } catch (err) {
        setError('Failed to load job listings');
        console.error('Error loading jobs:', err);
      } finally {
        setLoading(false);
      }
    };

    const handleUpdateStatus = async (jobId, newStatus) => {
      try {
        const response = await fetch(`https://www.phynux.tech/api/jobs/${jobId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status: newStatus }),
        });

        if (!response.ok) throw new Error('Failed to update status');

        const updatedJob = await response.json();
        setJobs(jobs.map(j =>
          j.id === jobId ? updatedJob : j
        ));
        setSelectedJob(updatedJob);
      } catch (error) {
        console.error('Error updating job status:', error);
      }
    };

    const handleDeleteJob = async (jobId) => {
      try {
        const response = await fetch(`https://www.phynux.tech/api/jobs/${jobId}`, {
          method: 'DELETE',
        });

        if (!response.ok) throw new Error('Failed to delete job');

        setJobs(jobs.filter(j => j.id !== jobId));
        setSelectedJob(null);
      } catch (error) {
        console.error('Error deleting job:', error);
      }
    };

    const handleNewJobSuccess = (newJob) => {
      setJobs([newJob, ...jobs]);
      setActiveTab('list');
    };

    const handleUpdateJob = (updatedJob) => {
      setJobs(jobs.map(j =>
        j.id === updatedJob.id ? updatedJob : j
      ));
    };

    const filteredJobs = jobs.filter(job => {
      const searchTerms = searchQuery.toLowerCase();
      return (
        job.title.toLowerCase().includes(searchTerms) ||
        job.location.toLowerCase().includes(searchTerms) ||
        job.type.toLowerCase().includes(searchTerms)
      );
    });

    if (loading) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      );
    }

    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8">
        {/* Tab Navigation */}
        <div className="flex w-full sm:w-auto space-x-4 mb-8">
        <button
            onClick={() => setActiveTab('list')}
            className={`flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors ${
            activeTab === 'list'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            }`}
        >
            <Briefcase className="w-5 h-5" />
            <span>Job Listings</span>
        </button>
        <button
            onClick={() => setActiveTab('applications')}
            className={`flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors ${
            activeTab === 'applications'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            }`}
        >
            <Users className="w-5 h-5" />
            <span>Applications</span>
        </button>
        <button
            onClick={() => setActiveTab('new')}
            className={`flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors ${
            activeTab === 'new'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            }`}
        >
            <Plus className="w-5 h-5" />
            <span>New Job</span>
        </button>
        </div>

        {/* Content */}
        {activeTab === 'list' ? (
          <div className="space-y-6">
            {/* Search Bar */}
            <div className="relative max-w-xl">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search by title, location, or type..."
                className="w-full bg-black/50 border border-gray-800 rounded-lg pl-10 pr-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
              />
              <Search className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
            </div>

            {/* Jobs Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredJobs.length > 0 ? (
                filteredJobs.map((job, index) => (
                  <motion.div
                    key={job.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-4 cursor-pointer hover:bg-gray-800/50 transition-colors"
                    onClick={() => setSelectedJob(job)}
                  >
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="font-semibold mb-1">{job.title}</h3>
                        <p className="text-sm text-gray-400">{job.location}</p>
                      </div>
                      <div className={`px-2 py-1 rounded-full text-xs ${
                        job.status === 'Active' ? 'bg-green-500/10 text-green-400' :
                        job.status === 'Draft' ? 'bg-yellow-500/10 text-yellow-400' :
                        'bg-red-500/10 text-red-400'
                      }`}>
                        {job.status}
                      </div>
                    </div>
                    <div className="space-y-2 text-sm text-gray-400">
                      <div className="flex items-center gap-2">
                        <Briefcase className="w-4 h-4" />
                        {job.type}
                      </div>
                      <div className="flex items-center gap-2">
                        <DollarSign className="w-4 h-4" />
                        {job.salary}
                      </div>
                      <div className="flex items-center gap-2">
                        <Clock className="w-4 h-4" />
                        {new Date(job.created_at).toLocaleDateString()}
                      </div>
                    </div>
                  </motion.div>
                ))
              ) : (
                <div className="col-span-full flex flex-col items-center justify-center py-12 text-gray-400">
                  <Briefcase className="w-12 h-12 mb-4" />
                  <p className="text-lg mb-2">No job listings found</p>
                  <p className="text-sm">
                    {searchQuery ? 'Try adjusting your search' : 'Add your first job listing to get started'}
                  </p>
                  {!searchQuery && (
                    <button
                      onClick={() => setActiveTab('new')}
                      className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors"
                    >
                      Add Job Listing
                    </button>
                  )}
                </div>
              )}
            </div>

            {/* Job Modal */}
            <AnimatePresence>
              {selectedJob && (
                <JobModal
                  job={selectedJob}
                  onClose={() => setSelectedJob(null)}
                  onUpdateStatus={handleUpdateStatus}
                  onDelete={handleDeleteJob}
                  onEdit={(job) => {
                    setEditingJob(job);
                    setSelectedJob(null);
                  }}
                />
              )}
            </AnimatePresence>

            {/* Edit Form Modal */}
            <AnimatePresence>
              {editingJob && (
                <EditJobForm
                  job={editingJob}
                  onClose={() => setEditingJob(null)}
                  onSuccess={(updatedJob) => {
                    handleUpdateJob(updatedJob);
                    setEditingJob(null);
                  }}
                />
              )}
            </AnimatePresence>
          </div>
        ): activeTab === 'applications' ? (
            <JobApplications />
          ) : (
          <NewJobForm onSuccess={handleNewJobSuccess} />
        )}
      </div>
    );
  };

  export default JobsList;