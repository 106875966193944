// services/jobsService.js

const BASE_URL = 'https://www.phynux.tech/api';

export const jobsService = {
  // Fetch all jobs
  getJobs: async () => {
    const response = await fetch(`${BASE_URL}/jobs`);
    if (!response.ok) {
      throw new Error('Failed to fetch jobs');
    }
    return response.json();
  },

  // Submit job application
  submitApplication: async (jobId, formData) => {
    // Create a multipart form data to handle file upload
    const form = new FormData();
    
    // Add all text fields
    Object.keys(formData).forEach(key => {
      if (key !== 'resume') {
        form.append(key, formData[key]);
      }
    });

    // Add resume file if exists
    if (formData.resume) {
      form.append('resume', formData.resume);
    }

    const response = await fetch(`${BASE_URL}/jobs/${jobId}/applications`, {
      method: 'POST',
      body: form
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to submit application');
    }

    return response.json();
  }
};