import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
    X, Search, UserPlus, Mail, DollarSign, Phone, MapPin, Building, Calendar, Save, Users, ChevronDown, Trash2, Edit
} from 'lucide-react';

  const industries = [
    'Technology',
    'Healthcare',
    'Finance',
    'Manufacturing',
    'Retail',
    'Education',
    'Construction',
    'Energy',
    'Transportation',
    'Fitness',
    'Other'
  ];
  
  const NewClientForm = ({ onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
      name: '',
      contact_person: '',
      email: '',
      phone: '',
      location: '',
      industry: '',
      contract_value: '',
      notes: '',
      status: 'Pending'
    });
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError(null);
      setLoading(true);
  
      try {
        const response = await fetch('https://www.phynux.tech/api/clients', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...formData,
            contract_value: parseFloat(formData.contract_value) || 0
          }),
        });
  
        const data = await response.json();
  
        if (!response.ok) {
          throw new Error(data.error || 'Failed to create client');
        }
  
        onSuccess(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    return (
      <div className="max-w-4xl mx-auto">
        {error && (
          <div className="bg-red-500/20 text-red-400 px-4 py-3 rounded-lg mb-6">
            {error}
          </div>
        )}
  
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Company Name */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Company Name *
              </label>
              <input
                type="text"
                name="name"
                required
                value={formData.name}
                onChange={handleChange}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
  
            {/* Industry */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Industry *
              </label>
              <select
                name="industry"
                required
                value={formData.industry}
                onChange={handleChange}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="">Select industry</option>
                {industries.map(industry => (
                  <option key={industry} value={industry}>
                    {industry}
                  </option>
                ))}
              </select>
            </div>
  
            {/* Contact Person */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Contact Person *
              </label>
              <input
                type="text"
                name="contact_person"
                required
                value={formData.contact_person}
                onChange={handleChange}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
  
            {/* Email */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Email *
              </label>
              <input
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
  
            {/* Phone */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Phone
              </label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
  
            {/* Location */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Location
              </label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
  
            {/* Contract Value */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Contract Value
              </label>
              <div className="relative">
                <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400">$</span>
                <input
                  type="number"
                  name="contract_value"
                  min="0"
                  step="0.01"
                  value={formData.contract_value}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg pl-8 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>
  
            {/* Status */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Status
              </label>
              <select
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="Pending Sale">Pending Sale</option>
                <option value="Potental Sale">Potental Sale</option>
                <option value="Sold">Sold</option>
                <option value="Declined">Declined</option>
              </select>
            </div>
          </div>
  
          {/* Notes */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Notes
            </label>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              rows={4}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Add any additional notes about the client..."
            />
          </div>
  
          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className="inline-flex items-center gap-2 px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors disabled:opacity-50"
            >
              {loading ? (
                <>
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  Creating Client...
                </>
              ) : (
                <>
                  <Save className="w-5 h-5" />
                  Create Client
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const EditClientForm = ({ client, onClose, onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
      name: '',
      contact_person: '',
      email: '',
      phone: '',
      location: '',
      industry: '',
      contract_value: '',
      notes: '',
      status: 'Pending Sale'
    });
  
    useEffect(() => {
      if (client) {
        setFormData({
          name: client.name || '',
          contact_person: client.contact_person || '',
          email: client.email || '',
          phone: client.phone || '',
          location: client.location || '',
          industry: client.industry || '',
          contract_value: client.contract_value || '',
          notes: client.notes || '',
          status: client.status || 'Pending Sale'
        });
      }
    }, [client]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError(null);
      setLoading(true);
  
      try {
        const response = await fetch(`https://www.phynux.tech/api/clients/${client.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...formData,
            contract_value: parseFloat(formData.contract_value) || 0
          }),
        });
  
        const data = await response.json();
  
        if (!response.ok) {
          throw new Error(data.error || 'Failed to update client');
        }
  
        onSuccess(data);
        onClose();
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
        <div className="bg-gray-900 rounded-xl p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Edit Client</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-white/5 rounded-lg transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
  
          {error && (
            <div className="bg-red-500/20 text-red-400 px-4 py-3 rounded-lg mb-6">
              {error}
            </div>
          )}
  
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Company Name */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Company Name *
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
  
              {/* Industry */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Industry *
                </label>
                <select
                  name="industry"
                  required
                  value={formData.industry}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="">Select industry</option>
                  {industries.map(industry => (
                    <option key={industry} value={industry}>
                      {industry}
                    </option>
                  ))}
                </select>
              </div>
  
              {/* Contact Person */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Contact Person *
                </label>
                <input
                  type="text"
                  name="contact_person"
                  required
                  value={formData.contact_person}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
  
              {/* Email */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Email *
                </label>
                <input
                  type="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
  
              {/* Phone */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Phone
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
  
              {/* Location */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Location
                </label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
  
              {/* Contract Value */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Contract Value
                </label>
                <div className="relative">
                  <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400">$</span>
                  <input
                    type="number"
                    name="contract_value"
                    min="0"
                    step="0.01"
                    value={formData.contract_value}
                    onChange={handleChange}
                    className="w-full bg-gray-900/50 border border-gray-800 rounded-lg pl-8 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
              </div>
  
              {/* Status */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Status
                </label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="Pending Sale">Pending Sale</option>
                  <option value="Potental Sale">Potental Sale</option>
                  <option value="Sold">Sold</option>
                  <option value="Declined">Declined</option>
                </select>
              </div>
            </div>
  
            {/* Notes */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Notes
              </label>
              <textarea
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                rows={4}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Add any additional notes about the client..."
              />
            </div>
  
            {/* Submit Button */}
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="px-6 py-3 bg-gray-800 hover:bg-gray-700 rounded-lg transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="inline-flex items-center gap-2 px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors disabled:opacity-50"
              >
                {loading ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    Updating...
                  </>
                ) : (
                  <>
                    <Save className="w-5 h-5" />
                    Update Client
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };


  const statusOptions = [
    { value: 'Pending Sale' },
    { value: 'Potental Sale' },
    { value: 'Sold' },
    { value: 'Declined' }
  ];
  
  const ClientModal = ({ client, user, onClose, onUpdateStatus, onDelete, onEdit }) => {
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  
    const handleStatusChange = async (newStatus) => {
      try {
        await onUpdateStatus(client.id, newStatus);
        setShowStatusDropdown(false);
      } catch (error) {
        console.error('Error updating status:', error);
      }
    };
  
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-gray-900 rounded-xl p-6 max-w-lg w-full shadow-xl"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex justify-between items-start mb-6">
            <div>
              <h2 className="text-2xl font-bold mb-2">{client.name}</h2>
              <div className="relative">
                <button
                  onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                  className={`inline-flex items-center gap-2 px-3 py-1 rounded-full transition-colors ${
                    client.status === 'Sold' ? 'bg-green-500/10 text-green-400' :
                    client.status === 'Declined' ? 'bg-red-500/10 text-red-400' :
                    client.status === 'Potental Sale' ? 'bg-yellow-500/10 text-yellow-400' :
                    client.status === 'Pending Sale' ? 'bg-blue-500/10 text-blue-400' :
                    'bg-gray-500/10 text-gray-400'
                  }`}
                >
                  {client.status}
                  <ChevronDown className="w-4 h-4" />
                </button>
                
                <AnimatePresence>
                  {showStatusDropdown && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="absolute top-full left-0 mt-2 bg-gray-800 rounded-lg shadow-lg overflow-hidden z-50"
                    >
                      {statusOptions.map(option => (
                        <button
                          key={option.value}
                          onClick={() => handleStatusChange(option.value)}
                          className={`w-full px-4 py-2 text-left hover:bg-gray-700 transition-colors ${
                            option.value === client.status ? 'bg-gray-700' : ''
                          }`}
                        >
                          {option.value}
                        </button>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
                <div className="flex items-center gap-2">
                    {user['department'] == 'Operations' && (
                        <button
                        onClick={() => onEdit(client)}
                        className="p-2 hover:bg-blue-500/10 text-blue-400 rounded-lg transition-colors"
                        title="Edit client"
                        >
                            <Edit className="w-5 h-5" />
                        </button>
                    )}
                    {user['department'] == 'Operations' && (
                        <button
                            onClick={() => {
                                if (window.confirm('Are you sure you want to delete this client?')) {
                                onDelete(client.id);
                                }
                            }}
                            className="p-2 hover:bg-red-500/10 text-red-400 rounded-lg transition-colors"
                            >
                            <Trash2 className="w-5 h-5" />
                        </button>
                    )}
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-white/5 rounded-lg transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
          </div>
  
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-4">
                <div className="flex items-center gap-3 text-gray-300">
                  <UserPlus className="w-5 h-5" />
                  <div>
                    <p className="text-sm text-gray-400">Contact Person</p>
                    <p>{client.contact_person}</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 text-gray-300">
                  <Mail className="w-5 h-5" />
                  <div>
                    <p className="text-sm text-gray-400">Email</p>
                    <p>{client.email}</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 text-gray-300">
                  <Phone className="w-5 h-5" />
                  <div>
                    <p className="text-sm text-gray-400">Phone</p>
                    <p>{client.phone || 'N/A'}</p>
                  </div>
                </div>
              </div>
              
              <div className="space-y-4">
                <div className="flex items-center gap-3 text-gray-300">
                  <MapPin className="w-5 h-5" />
                  <div>
                    <p className="text-sm text-gray-400">Location</p>
                    <p>{client.location || 'N/A'}</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 text-gray-300">
                  <Building className="w-5 h-5" />
                  <div>
                    <p className="text-sm text-gray-400">Industry</p>
                    <p>{client.industry}</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 text-gray-300">
                  <DollarSign className="w-5 h-5" />
                  <div>
                    <p className="text-sm text-gray-400">Contract Value</p>
                    <p>${(client.contract_value || 0).toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="pt-4 border-t border-gray-800">
              <div className="flex items-center gap-3 text-gray-300 mb-4">
                <Calendar className="w-5 h-5" />
                <div>
                  <p className="text-sm text-gray-400">Last Contact</p>
                  <p>{client.last_contact ? new Date(client.last_contact).toLocaleDateString() : 'No contact recorded'}</p>
                </div>
              </div>
              <div>
                <p className="text-sm text-gray-400 mb-2">Notes</p>
                <p className="text-gray-300">{client.notes || 'No notes available'}</p>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    );
  };


  const ClientsList = () => {
    const [activeTab, setActiveTab] = useState('list');
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [editingClient, setEditingClient] = useState(null);
  
    useEffect(() => {
      fetchClients();
      const jsonData = sessionStorage.getItem('user');
      setUser(JSON.parse(jsonData))
    }, []);
  
    const fetchClients = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://www.phynux.tech/api/clients');
        const data = await response.json();
        setClients(data);
      } catch (err) {
        setError('Failed to load clients');
        console.error('Error loading clients:', err);
      } finally {
        setLoading(false);
      }
    };
  
    const handleUpdateStatus = async (clientId, newStatus) => {
      try {
        const response = await fetch(`https://www.phynux.tech/api/clients/${clientId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status: newStatus }),
        });
  
        if (!response.ok) throw new Error('Failed to update status');
  
        const updatedClient = await response.json();
        setClients(clients.map(c => 
          c.id === clientId ? updatedClient : c
        ));
        setSelectedClient(updatedClient);
      } catch (error) {
        console.error('Error updating client status:', error);
      }
    };
  
    const handleNewClientSuccess = (newClient) => {
      setClients([newClient, ...clients]);
      setActiveTab('list');
    };
  
    const filteredClients = clients.filter(client => {
      const searchTerms = searchQuery.toLowerCase();
      return (
        client.name.toLowerCase().includes(searchTerms) ||
        client.email.toLowerCase().includes(searchTerms) ||
        client.phone?.toLowerCase().includes(searchTerms) ||
        client.contact_person.toLowerCase().includes(searchTerms)
      );
    });

    const handleUpdateClient = (updatedClient) => {
        setClients(clients.map(c => 
          c.id === updatedClient.id ? updatedClient : c
        ));
        setSelectedClient(updatedClient);
      };

    const handleDeleteClient = async (clientId) => {
        try {
          const response = await fetch(`https://www.phynux.tech/api/clients/${clientId}`, {
            method: 'DELETE',
          });
      
          if (!response.ok) throw new Error('Failed to delete client');
      
          // Remove the client from the state
          setClients(clients.filter(c => c.id !== clientId));
          setSelectedClient(null); // Close the modal
        } catch (error) {
          console.error('Error deleting client:', error);
        }
      };
  
    if (loading) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      );
    }
  
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8">
        {/* Tab Navigation */}
        <div className="flex w-full sm:w-auto space-x-4 mb-8">
          <button
            onClick={() => setActiveTab('list')}
            className={`flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'list'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            }`}
          >
            <Users className="w-5 h-5" />
            <span>Clients</span>
          </button>
          {user['department'] == 'Operations' && (
            <button
                onClick={() => setActiveTab('new')}
                className={`flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'new'
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                }`}
            >
                <UserPlus className="w-5 h-5" />
                <span>New Client</span>
            </button>
          )}
        </div>
  
        {/* Content */}
        {activeTab === 'list' ? (
          <div className="space-y-6">
            {/* Search Bar */}
            <div className="relative max-w-xl">
              <input 
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search by name, email, phone, or contact..."
                className="w-full bg-black/50 border border-gray-800 rounded-lg pl-10 pr-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
              />
              <Search className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
            </div>
  
            {/* Clients Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredClients.length > 0 ? (
                filteredClients.map((client, index) => (
                  <motion.div
                    key={client.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-4 cursor-pointer hover:bg-gray-800/50 transition-colors"
                    onClick={() => setSelectedClient(client)}
                  >
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="font-semibold mb-1">{client.name}</h3>
                        <p className="text-sm text-gray-400">{client.industry}</p>
                      </div>
                      <div className={`px-2 py-1 rounded-full text-xs ${
                        client.status === 'Sold' ? 'bg-green-500/10 text-green-400' :
                        client.status === 'Declined' ? 'bg-red-500/10 text-red-400' :
                        client.status === 'Potental Sale' ? 'bg-yellow-500/10 text-yellow-400' :
                        client.status === 'Pending Sale' ? 'bg-blue-500/10 text-blue-400' :
                        'bg-gray-500/10 text-gray-400'
                      }`}>
                        {client.status}
                      </div>
                    </div>
                    <div className="space-y-2 text-sm text-gray-400">
                      <div className="flex items-center gap-2">
                        <UserPlus className="w-4 h-4" />
                        {client.contact_person}
                      </div>
                      <div className="flex items-center gap-2">
                        <Mail className="w-4 h-4" />
                        {client.email}
                      </div>
                      <div className="flex items-center gap-2">
                        <DollarSign className="w-4 h-4" />
                        ${client.contract_value.toLocaleString()}
                      </div>
                    </div>
                  </motion.div>
                ))
              ) : (
                <div className="col-span-full flex flex-col items-center justify-center py-12 text-gray-400">
                  <UserPlus className="w-12 h-12 mb-4" />
                  <p className="text-lg mb-2">No clients found</p>
                  <p className="text-sm">
                    {searchQuery ? 'Try adjusting your search' : 'Add your first client to get started'}
                  </p>
                  {!searchQuery && (
                    <button
                      onClick={() => setActiveTab('new')}
                      className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors"
                    >
                      Add Client
                    </button>
                  )}
                </div>
              )}
            </div>
  
            {/* Client Modal */}
            <AnimatePresence>
              {selectedClient && (
                <ClientModal
                    client={selectedClient}
                    user={user}
                    onClose={() => setSelectedClient(null)}
                    onUpdateStatus={handleUpdateStatus}
                    onDelete={handleDeleteClient}
                    onEdit={() => {
                        setEditingClient(selectedClient);
                        setSelectedClient(null);
                    }}
                />
              )}
            </AnimatePresence>
            <AnimatePresence>
                {editingClient && (
                    <EditClientForm
                    client={editingClient}
                    onClose={() => setEditingClient(null)}
                    onSuccess={(updatedClient) => {
                        handleUpdateClient(updatedClient);
                        setEditingClient(null);
                    }}
                    />
                )}
            </AnimatePresence>
          </div>
        ) : (
          <NewClientForm onSuccess={handleNewClientSuccess} />
        )}
      </div>
    );
  };
  
  export default ClientsList;