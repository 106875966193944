import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import {
  Users,
  UserPlus,
  Search,
  Edit,
  Trash2,
  MoreVertical,
  Save,
  X,
  Upload,
  Image as ImageIcon,
  AlertCircle,
  CheckCircle,
  List,
  BadgeCheck,
  Lock,
  Crop,
  Shield
} from 'lucide-react';
import ReactCrop from 'react-image-crop';
import { employeeService } from '../backendservice/staffService';
import { blogService } from '../backendservice/blogService';

const ImageUploader = ({ onImageSelect, previewImage, onImageClear }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [isCropping, setIsCropping] = useState(false);
    const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: 16 / 9 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const imgRef = useRef(null);

    const handleDrag = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragIn = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragOut = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    };

    const handleDrop = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const files = e.dataTransfer.files;
      if (files && files[0]) {
        handleFileSelect(files[0]);
      }
    };

    const handleFileSelect = async (file) => {
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          onImageSelect(file);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleCropComplete = (crop, percentCrop) => {
      setCompletedCrop(crop);
    };

    const handleCropImage = async () => {
      if (!completedCrop || !imgRef.current) return;

      const canvas = document.createElement('canvas');
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        imgRef.current,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width,
        completedCrop.height
      );

      const base64Image = await blogService.processCroppedImage(canvas);
      onImageSelect({ base64: base64Image });
      setIsCropping(false);
    };

    const customCropStyles = {
        className: "custom-crop-area",
        style: {
          // Add these styles to the container
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }
      };

    return (

      <div className="space-y-4">
        <style>
            {`
            /* Override ReactCrop default styles */
            .ReactCrop {
                background-color: rgba(0, 0, 0, 0.7);
            }
            .ReactCrop__crop-selection {
                border: 2px solid #3b82f6 !important; /* Blue border */
                box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.7);
            }
            .ReactCrop__drag-handle {
                background-color: #3b82f6 !important; /* Blue handles */
                border: 2px solid #fff !important;
                width: 15px !important;
                height: 15px !important;
            }
            .ReactCrop__drag-handle::after {
                background-color: #3b82f6 !important;
            }
            .ReactCrop__drag-bar {
                background-color: #3b82f6 !important; /* Blue drag bars */
            }
            .ReactCrop__crop-selection:focus {
                border-color: #2563eb !important; /* Darker blue when focused */
            }
            `}
        </style>
        {isCropping && previewImage ? (
          <div className="space-y-4">
            <div className="border-2 border-gray-800 rounded-lg overflow-hidden">
                <ReactCrop
                crop={crop}
                onChange={newCrop => setCrop(newCrop)}
                onComplete={handleCropComplete}
                aspect={16 / 9}
                {...customCropStyles}
                >
                <img
                    ref={imgRef}
                    src={typeof previewImage === 'string' ? previewImage : URL.createObjectURL(previewImage)}
                    className="max-w-full h-auto"
                />
                </ReactCrop>
            </div>
            <div className="flex justify-end gap-2">
                <button
                type="button"
                onClick={() => setIsCropping(false)}
                className="px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
                >
                Cancel
                </button>
                <button
                type="button"
                onClick={handleCropImage}
                className="px-4 py-2 bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
                >
                Apply Crop
                </button>
            </div>
            </div>
        ) : (
          <div className="relative">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileSelect(e.target.files[0])}
              className="hidden"
              id="image-upload"
            />
            {previewImage ? (
              <div className="relative rounded-lg overflow-hidden">
                <img
                  src={typeof previewImage === 'string' ? previewImage : URL.createObjectURL(previewImage)}
                  alt="Preview"
                  className="w-full h-48 object-cover"
                />
                <div className="absolute top-2 right-2 flex gap-2">
                  <button
                    type="button"
                    onClick={() => setIsCropping(true)}
                    className="p-1 bg-black/50 rounded-full hover:bg-black/70 transition-colors"
                  >
                    <Crop className="w-5 h-5" />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      onImageClear();
                      setIsCropping(false);
                    }}
                    className="p-1 bg-black/50 rounded-full hover:bg-black/70 transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              </div>
            ) : (
              <label
                htmlFor="image-upload"
                className={`flex flex-col items-center justify-center w-full h-48 border-2 border-dashed rounded-lg cursor-pointer transition-colors ${
                  isDragging
                    ? 'border-blue-500 bg-blue-500/10'
                    : 'border-gray-800 hover:border-blue-500/50'
                }`}
                onDragEnter={handleDragIn}
                onDragLeave={handleDragOut}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              >
                <motion.div
                  animate={isDragging ? { scale: 1.1 } : { scale: 1 }}
                  className="flex flex-col items-center"
                >
                  {isDragging ? (
                    <Upload className="w-8 h-8 text-blue-400 mb-2" />
                  ) : (
                    <ImageIcon className="w-8 h-8 text-gray-400 mb-2" />
                  )}
                  <span className="text-sm text-gray-400">
                    {isDragging ? 'Drop image here' : 'Click or drag image to upload'}
                  </span>
                </motion.div>
              </label>
            )}
          </div>
        )}
      </div>
    );
  };

  const DropdownMenu = ({ children, items }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
        >
          {children}
        </button>
        {isOpen && (
          <>
            <div
              className="fixed inset-0 z-10"
              onClick={() => setIsOpen(false)}
            />
            <div className="absolute right-0 mt-2 w-48 bg-gray-900 border border-gray-800 rounded-lg shadow-lg overflow-hidden z-20">
              {items.map((item, index) => (
                <button
                  key={index}
                  onClick={() => {
                    item.onClick();
                    setIsOpen(false);
                  }}
                  className={`w-full flex items-center px-4 py-2 text-sm ${item.className} hover:bg-gray-800`}
                >
                  {item.icon}
                  <span className="ml-2">{item.label}</span>
                </button>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };


  const dashboardAccess = {
    analytics: "Dashboard",
    employees: "Employee Management",
    blogs: "Blog Management",
    customers: "Customer Management",
    devices: "Device Management",
    jobs: 'Job Listings',
    settings: "System Settings"
  };

  const accessRoles = {
    admin: "Administrator",
    manager: "Manager",
    editor: "Content Editor",
    viewer: "Viewer",
    custom: "Custom Access"
  };

  const rolePresets = {
    admin: Object.keys(dashboardAccess), // All access
    manager: ['analytics', 'employees', 'blogs', 'customers', 'products', 'orders', 'inventory'],
    editor: ['blogs', 'marketing'],
    viewer: ['analytics']
  };

const EmployeeForm = ({ editEmployee = null, onSuccess }) => {
    const [formData, setFormData] = useState({
        employeeNumber: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        department: '',
        position: '',
        startDate: '',
        address: '',
        password: '',
        status: 'Active',
        image: null,
        role: 'viewer',
        accessRights: []
    });

    const [previewImage, setPreviewImage] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [isCustomRole, setIsCustomRole] = useState(false);

  const departments = [
    "Engineering",
    "Design",
    "Product",
    "Marketing",
    "Sales",
    "HR",
    "Finance",
    "Operations"
  ];


  useEffect(() => {
    if (editEmployee) {
      setFormData({
        ...editEmployee,
        password: '' // Don't populate password for security
      });
      setIsCustomRole(editEmployee.role === 'custom');
      if (editEmployee.image) {
        setPreviewImage(editEmployee.image);
      }
    } else {
      generateEmployeeNumber();
    }
  }, [editEmployee]);

  // Update access rights when role changes
  useEffect(() => {
    if (formData.role !== 'custom') {
      setFormData(prev => ({
        ...prev,
        accessRights: rolePresets[formData.role] || []
      }));
    }
  }, [formData.role]);

  const handleRoleChange = (e) => {
    const newRole = e.target.value;
    setIsCustomRole(newRole === 'custom');
    setFormData(prev => ({
      ...prev,
      role: newRole,
      accessRights: newRole === 'custom' ? prev.accessRights : (rolePresets[newRole] || [])
    }));
  };

  const toggleAccess = (accessKey) => {
    if (!isCustomRole) return;

    setFormData(prev => ({
      ...prev,
      accessRights: prev.accessRights.includes(accessKey)
        ? prev.accessRights.filter(key => key !== accessKey)
        : [...prev.accessRights, accessKey]
    }));
  };

  useEffect(() => {
    if (editEmployee) {
      setFormData({
        employeeNumber: editEmployee.employeeNumber || '',
        firstName: editEmployee.first_name || '',
        lastName: editEmployee.last_name || '',
        email: editEmployee.email || '',
        phone: editEmployee.phone || '',
        department: editEmployee.department || '',
        position: editEmployee.position || '',
        startDate: editEmployee.startDate || '',
        address: editEmployee.address || '',
        status: editEmployee.status || 'Active',
        password: '', // Don't populate password for security
        role: editEmployee.role || 'viewer',
        accessRights: editEmployee.accessRights || [], // Initialize access rights array
        image: editEmployee.image || null
      });
      setIsCustomRole(editEmployee.role === 'custom');
      if (editEmployee.image) {
        setPreviewImage(editEmployee.image);
      }
    } else {
      generateEmployeeNumber();
      // Set default access rights for new employees
      setFormData(prev => ({
        ...prev,
        role: 'viewer',
        accessRights: rolePresets['viewer'] || []
      }));
    }
  }, [editEmployee]);

  const generateEmployeeNumber = () => {
    // Generate a unique employee number (you can modify this logic)
    const prefix = 'EMP';
    const randomNum = Math.floor(10000 + Math.random() * 90000);
    setFormData(prev => ({
      ...prev,
      employeeNumber: `${prefix}${randomNum}`
    }));
  };

  const handleImageUpload = async (imageData) => {
    try {
      if (imageData.base64) {
        setFormData({ ...formData, image: imageData.base64 });
        setPreviewImage(imageData.base64);
      } else {
        const processedImage = await employeeService.processImage(imageData);
        setFormData({ ...formData, image: processedImage });
        setPreviewImage(processedImage);
      }
    } catch (err) {
      setError('Failed to process image');
      console.error('Image processing error:', err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    setError(null);

    try {
      if (editEmployee) {
        await employeeService.updateEmployee(editEmployee.id, formData);
      } else {
        await employeeService.createEmployee(formData);
      }

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        if (onSuccess) onSuccess();
      }, 1500);
    } catch (err) {
      setError(err.message);
      console.error('Form submission error:', err);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="bg-red-500/20 text-red-400 px-4 py-3 rounded-lg flex items-center gap-2">
          <AlertCircle className="w-5 h-5" />
          {error}
        </div>
      )}

      {showSuccess && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-green-500/20 text-green-400 px-4 py-3 rounded-lg flex items-center gap-2"
        >
          <CheckCircle className="w-5 h-5" />
          Employee {editEmployee ? 'updated' : 'created'} successfully!
        </motion.div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Employee Number */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Employee Number
              </label>
              <input
                type="text"
                value={formData.employeeNumber}
                disabled
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent opacity-50"
              />
            </div>

            {/* Department */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Department *
              </label>
              <select
                required
                value={formData.department}
                onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="">Select department</option>
                {departments.map((dept) => (
                  <option key={dept} value={dept}>
                    {dept}
                  </option>
                ))}
              </select>
            </div>

            {/* First Name */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                First Name *
              </label>
              <input
                type="text"
                required
                value={formData.firstName}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Last Name */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Last Name *
              </label>
              <input
                type="text"
                required
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Email */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Email *
              </label>
              <input
                type="email"
                required
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Phone */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Phone *
              </label>
              <input
                type="tel"
                required
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Position */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Position *
              </label>
              <input
                type="text"
                required
                value={formData.position}
                onChange={(e) => setFormData({ ...formData, position: e.target.value })}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Start Date */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Start Date *
              </label>
              <input
                type="date"
                required
                value={formData.startDate}
                onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Password */}
            {!editEmployee && (
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Initial Password *
                </label>
                <input
                  type="password"
                  required={!editEmployee}
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            )}

            {/* Address */}
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Address *
              </label>
              <textarea
                required
                value={formData.address}
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent h-24"
              />
            </div>
          </div>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">


            {/* Role Selection */}
          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-300">
              Access Role
            </label>
            <select
              value={formData.role}
              onChange={handleRoleChange}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              {Object.entries(accessRoles).map(([key, label]) => (
                <option key={key} value={key}>{label}</option>
              ))}
            </select>
          </div>

          {/* Access Rights */}
          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-300">
              Dashboard Access
            </label>
            <div className="space-y-2">
              {Object.entries(dashboardAccess).map(([key, label]) => (
                <div
                  key={key}
                  onClick={() => toggleAccess(key)}
                  className={`flex items-center justify-between p-3 rounded-lg border cursor-pointer transition-colors ${
                    formData.accessRights.includes(key)
                      ? 'bg-blue-500/10 border-blue-500/50 text-blue-400'
                      : 'bg-gray-900/50 border-gray-800 text-gray-400'
                  } ${!isCustomRole && 'opacity-75 cursor-not-allowed'}`}
                >
                  <div className="flex items-center gap-2">
                    <Shield className="w-4 h-4" />
                    <span className="text-sm">{label}</span>
                  </div>
                  {!formData.accessRights.includes(key) && (
                    <Lock className="w-4 h-4" />
                  )}
                </div>
              ))}
            </div>
            {!isCustomRole && (
              <p className="text-sm text-gray-500">
                Select "Custom Access" role to modify individual permissions
              </p>
            )}
          </div>
          {/* Save Button */}
          <button
            type="submit"
            disabled={isSaving}
            className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors disabled:opacity-50"
          >
            {isSaving ? (
              <>
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                Saving...
              </>
            ) : (
              <>
                <Save className="w-5 h-5" />
                {editEmployee ? 'Update Employee' : 'Create Employee'}
              </>
            )}
          </button>

          {/* Profile Image */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Profile Image
            </label>
            <ImageUploader
              onImageSelect={handleImageUpload}
              previewImage={previewImage}
              onImageClear={() => {
                setPreviewImage(null);
                setFormData({ ...formData, image: null });
              }}
            />
          </div>

          {/* Status */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Status
            </label>
            <select
              value={formData.status}
              onChange={(e) => setFormData({ ...formData, status: e.target.value })}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="Active">Active</option>
              <option value="On Leave">On Leave</option>
              <option value="Terminated">Terminated</option>
            </select>
          </div>
        </div>
      </div>
    </form>
  );
};

const EmployeeDashboard = () => {
  const [activeTab, setActiveTab] = useState('list');
  const [searchQuery, setSearchQuery] = useState('');
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccessMessage] = useState(null);


  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      setIsLoading(true);
      const data = await employeeService.getEmployees();
      setEmployees(data);
      setError(null);  // Clear any existing errors
    } catch (err) {
      console.error('Error fetching employees:', err);
      setError(err.message || 'Failed to fetch employees');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (employee) => {
    try {
      setIsLoading(true);
      const fullEmployee = await employeeService.getEmployee(employee.id);
      // Transform the data to match form fields
      const employeeData = {
        ...fullEmployee,
        first_name: fullEmployee.first_name,
        last_name: fullEmployee.last_name,
        accessRights: fullEmployee.accessRights || [],
        role: fullEmployee.role || 'viewer'
      };
      setEditingEmployee(employeeData);
      setActiveTab('edit');
    } catch (err) {
      setError('Failed to fetch employee details');
      console.error('Error fetching employee details:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (employeeId) => {
    if (window.confirm('Are you sure you want to delete this employee?')) {
      try {
        await employeeService.deleteEmployee(employeeId);
        setEmployees(prev => prev.filter(emp => emp.id !== employeeId));
        setSuccessMessage('Employee deleted successfully');
        setTimeout(() => setSuccessMessage(null), 3000);
      } catch (err) {
        console.error('Error deleting employee:', err);
        setError(err.message || 'Failed to delete employee');
        setTimeout(() => setError(null), 3000);
      }
    }
  };

  const filteredEmployees = employees.filter(employee =>
    `${employee.first_name} ${employee.last_name}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
    employee.employee_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
    employee.department.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const EmployeeActions = ({ employee }) => (
    <DropdownMenu
      items={[
        {
          icon: <Edit className="w-4 h-4" />,
          label: 'Edit',
          onClick: () => handleEdit(employee),
          className: 'text-blue-400'
        },
        {
          icon: <Trash2 className="w-4 h-4" />,
          label: 'Delete',
          onClick: () => handleDelete(employee.id),
          className: 'text-red-400'
        }
      ]}
    >
      <MoreVertical className="w-5 h-5" />
    </DropdownMenu>
  );

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-4 sm:py-8">
        {/* Tab Navigation */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 sm:mb-8 space-y-4 sm:space-y-0">
          <div className="flex w-full sm:w-auto space-x-2 sm:space-x-4">
            <button
              onClick={() => {
                setActiveTab('list');
                setEditingEmployee(null);
              }}
              className={`flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'list'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
              }`}
            >
              <Users className="w-5 h-5" />
              <span className="sm:inline">Employees</span>
            </button>
            <button
              onClick={() => {
                setActiveTab('create');
                setEditingEmployee(null);
              }}
              className={`flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'create'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
              }`}
            >
              <UserPlus className="w-5 h-5" />
              <span className="sm:inline">Add Employee</span>
            </button>
          </div>
        </div>

        {/* Employee List View */}
        {activeTab === 'list' && (
          <div className="space-y-4 sm:space-y-6">
            {error && (
              <div className="bg-red-500/20 text-red-400 px-4 py-3 rounded-lg">
                {error}
              </div>
            )}
            {success && (
              <div className="bg-green-500/20 text-green-400 px-4 py-3 rounded-lg">
                {success}
              </div>
            )}

            {/* Search Bar */}
            <div className="relative">
              <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search employees..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg pl-10 pr-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {isLoading ? (
              <div className="flex justify-center items-center py-12">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
              </div>
            ) : (
              <>
                {/* Desktop View */}
                <div className="hidden sm:block bg-gray-900/50 border border-gray-800 rounded-lg overflow-hidden">
                <table className="w-full">
                    <thead>
                    <tr className="border-b border-gray-800">
                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Employee</th>
                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">ID</th>
                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Department</th>
                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Position</th>
                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Status</th>
                        <th className="px-6 py-4 text-right text-sm font-medium text-gray-300">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredEmployees.map((employee) => (
                        <tr key={employee.id} className="border-b border-gray-800 last:border-0">
                        <td className="px-6 py-4">
                            <div className="flex items-center gap-3">
                            {employee.image_data ? (
                                <img
                                src={employee.image_data}
                                alt={`${employee.first_name} ${employee.last_name}`}
                                className="w-8 h-8 rounded-full object-cover"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center">
                                <span className="text-sm font-medium">
                                    {employee.first_name[0]}{employee.last_name[0]}
                                </span>
                                </div>
                            )}
                            <div>
                                <div className="font-medium">{`${employee.first_name} ${employee.last_name}`}</div>
                                <div className="text-sm text-gray-400">{employee.email}</div>
                                <div className="flex items-center gap-1 mt-1">
                                <Shield className="w-3 h-3 text-blue-400" />
                                <span className="text-xs text-blue-400">
                                    {accessRoles[employee.role] || "No Role"}
                                </span>
                                </div>
                            </div>
                            </div>
                        </td>
                        <td className="px-6 py-4 text-gray-300">{employee.employee_number}</td>
                        <td className="px-6 py-4 text-gray-300">{employee.department}</td>
                        <td className="px-6 py-4 text-gray-300">{employee.position}</td>
                        <td className="px-6 py-4">
                            <span
                            className={`px-2 py-1 text-xs rounded-full ${
                                employee.status === 'Active'
                                ? 'bg-green-500/20 text-green-400'
                                : employee.status === 'On Leave'
                                ? 'bg-yellow-500/20 text-yellow-400'
                                : 'bg-red-500/20 text-red-400'
                            }`}
                            >
                            {employee.status}
                            </span>
                        </td>
                        <td className="px-6 py-4">
                            <div className="flex justify-end gap-2">
                            <button
                                onClick={() => handleEdit(employee)}
                                className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
                            >
                                <Edit className="w-4 h-4 text-blue-400" />
                            </button>
                            <button
                                onClick={() => handleDelete(employee.id)}
                                className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
                            >
                                <Trash2 className="w-4 h-4 text-red-400" />
                            </button>
                            </div>
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                </div>

                {/* Mobile View */}
                <div className="sm:hidden space-y-4">
                  {filteredEmployees.map((employee) => (
                    <div
                      key={employee.id}
                      className="bg-gray-900/50 border border-gray-800 rounded-lg p-4"
                    >
                      <div className="flex justify-between items-start mb-3">
                        <div className="flex items-center gap-3">
                          {employee.image ? (
                            <img
                              src={employee.image}
                              alt={`${employee.first_name} ${employee.last_name}`}
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          ) : (
                            <div className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center">
                              <span className="text-sm font-medium">
                                {employee.first_name[0]}{employee.last_name[0]}
                              </span>
                            </div>
                          )}
                          <div>
                            <div className="font-medium">{`${employee.first_name} ${employee.lastName}`}</div>
                            <div className="text-sm text-gray-400">{employee.email}</div>
                          </div>
                        </div>
                        <EmployeeActions employee={employee} />
                      </div>

                      <div className="space-y-2 text-sm text-gray-400">
                        <div className="flex items-center gap-2">
                          <BadgeCheck className="w-4 h-4" />
                          {employee.employeeNumber}
                        </div>
                        <div className="flex items-center gap-2">
                          <Users className="w-4 h-4" />
                          {employee.department} - {employee.position}
                        </div>
                      </div>

                      <div className="mt-3">
                        <span
                          className={`px-2 py-1 text-xs rounded-full ${
                            employee.status === 'Active'
                              ? 'bg-green-500/20 text-green-400'
                              : employee.status === 'On Leave'
                              ? 'bg-yellow-500/20 text-yellow-400'
                              : 'bg-red-500/20 text-red-400'
                          }`}
                        >
                          {employee.status}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}

        {/* Create/Edit Employee View */}
        {(activeTab === 'create' || activeTab === 'edit') && (
          <EmployeeForm
            editEmployee={editingEmployee}
            onSuccess={() => {
              fetchEmployees();
              setActiveTab('list');
            }}
          />
        )}
      </div>
    </div>

  );
};

export default EmployeeDashboard;