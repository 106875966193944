const API_URL = 'https://www.phynux.tech/api';

export const authService = {
  async login(employeeId, password, rememberMe) {
    try {
      const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ employeeId, password, rememberMe }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Login failed');
      }

      // Store token and user data
      if (rememberMe) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
      } else {
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('user', JSON.stringify(data.user));
      }

      return data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },

  async verifyToken() {
    try {
      const token = this.getToken();

      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${API_URL}/auth/verify-token`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (!response.ok) {
        this.logout();
        throw new Error(data.error || 'Token verification failed');
      }

      // Update stored user data
      const storage = this.getToken().startsWith('remember_') ? localStorage : sessionStorage;
      storage.setItem('user', JSON.stringify(data.user));

      return data.user;
    } catch (error) {
      console.error('Token verification error:', error);
      throw error;
    }
  },

  async requestPasswordReset(employeeId) {
    try {
      const response = await fetch(`${API_URL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ employeeId }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Password reset request failed');
      }

      return data;
    } catch (error) {
      console.error('Password reset request error:', error);
      throw error;
    }
  },

  getToken() {
    return localStorage.getItem('token') || sessionStorage.getItem('token');
  },

  getUser() {
    const userStr = localStorage.getItem('user') || sessionStorage.getItem('user');
    return userStr ? JSON.parse(userStr) : null;
  },

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
  },

  isAuthenticated() {
    return !!this.getToken();
  }
};