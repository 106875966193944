import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import {
  Users, Briefcase, Clock, ChevronDown, Bell, Search,
  BarChart2, Settings, Menu, X, UserPlus, Mail, Phone,
  Calendar, DollarSign, Building, MapPin,
  Wifi, Upload,
  DockIcon
} from 'lucide-react';
import '../webpages/Phynux.css'
import BlogManagement from './tabs/blog';
import StaffManagement from './tabs/staff';
import ClientsList from './tabs/clients';
import { useHistory } from 'react-router-dom';
import { MonetizationOn, Money, People } from '@mui/icons-material';
import JobsList from './tabs/joblistings';


const mockDevices = {
  models: [
    {
      id: 1,
      name: "ArcLock",
      version: "2.1.0",
      devices: [
        {
          serial: "SH-2024-001",
          status: "Online",
          lastUpdate: "2024-01-01",
          softwareVersion: "1.2.3",
          profile: "development"
        },
        {
          serial: "SH-2024-002",
          status: "Offline",
          lastUpdate: "2023-12-28",
          softwareVersion: "1.2.2",
          profile: "production"
        }
      ]
    },
    {
      id: 2,
      name: "AxonGate",
      version: "1.5.2",
      devices: [
        {
          serial: "SN-2023-055",
          status: "Online",
          lastUpdate: "2024-01-02",
          softwareVersion: "1.1.0",
          profile: "production"
        }
      ]
    }
  ]
};


const mockUserData = {
  name: "Alex Johnson",
  email: "alex@company.com",
  role: "Sales Representative",
  avatar: "/api/placeholder/80/80",
  preferences: {
    notifications: true,
    emailUpdates: true,
    darkMode: true,
    twoFactorAuth: false
  },
  teamId: "T-123456",
  lastLogin: "2024-01-02T09:30:00Z"
};

const mockData = {
  revenueData: [
    { name: 'Jan', value: 4000 },
    { name: 'Feb', value: 3000 },
    { name: 'Mar', value: 5000 },
    { name: 'Apr', value: 4500 },
    { name: 'May', value: 6000 },
    { name: 'Jun', value: 5500 }
  ],
  projects: [
    { name: 'Cloud Migration', progress: 75, status: 'In Progress' },
    { name: 'Security Audit', progress: 90, status: 'Review' },
    { name: 'AI Implementation', progress: 30, status: 'In Progress' }
  ],
  team: [
    { name: 'Sarah Chen', role: 'Lead Engineer' },
    { name: 'Mike Ross', role: 'DevOps' },
    { name: 'Anna Kim', role: 'UI Designer' }
  ],
  clients: [
    {
      id: 1,
      name: 'Acme Corporation',
      contact: 'John Smith',
      email: 'john@acme.com',
      phone: '+1 (555) 123-4567',
      location: 'New York, NY',
      industry: 'Technology',
      value: 250000,
      lastContact: '2024-12-28',
      status: 'Active',
      notes: 'Interested in cloud migration services'
    },
    {
      id: 2,
      name: 'Global Innovations',
      contact: 'Maria Garcia',
      email: 'maria@globalinv.com',
      phone: '+1 (555) 987-6543',
      location: 'San Francisco, CA',
      industry: 'Healthcare',
      value: 180000,
      lastContact: '2024-12-25',
      status: 'Pending',
      notes: 'Following up on security proposal'
    }
  ],
  notifications: [
    { id: 1, title: 'New Client', message: 'Acme Corp signed the contract', time: '5m ago', unread: true },
    { id: 2, title: 'Project Update', message: 'Cloud migration 75% complete', time: '1h ago', unread: true },
    { id: 3, title: 'Meeting Reminder', message: 'Team standup in 15 minutes', time: '2h ago', unread: false },
    { id: 4, title: 'System Alert', message: 'Server utilization above 90%', time: '3h ago', unread: false },
  ]
};

const DeviceDetails = ({ device, onClose, onProfileChange }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        className="bg-gray-900 rounded-xl p-6 max-w-xl w-full"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-start mb-6">
          <h2 className="text-2xl font-bold">Device Details</h2>
          <button onClick={onClose} className="p-2 hover:bg-white/5 rounded-lg">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-6">
          <div className="space-y-4">
            <div className="p-4 bg-blue-500/10 rounded-lg">
              <p className="font-semibold mb-2">Serial: {device.serial}</p>
              <p className="text-sm text-gray-400">Software Version: {device.softwareVersion}</p>
              <p className="text-sm text-gray-400">Last Update: {device.lastUpdate}</p>
              <div className={`mt-2 inline-block px-2 py-1 rounded-full text-xs ${
                device.status === 'Online'
                  ? 'bg-green-500/10 text-green-400'
                  : 'bg-red-500/10 text-red-400'
              }`}>
                {device.status}
              </div>
            </div>

            <div>
              <label className="block text-sm text-gray-400 mb-2">Profile Configuration</label>
              <div className="flex space-x-4">
                <button
                  onClick={() => onProfileChange(device.serial, 'development')}
                  className={`flex-1 p-4 rounded-lg border ${
                    device.profile === 'development'
                      ? 'border-blue-500 bg-blue-500/10'
                      : 'border-gray-800 hover:bg-white/5'
                  }`}
                >
                  <h4 className="font-medium mb-1">Development</h4>
                  <p className="text-sm text-gray-400">Debug enabled</p>
                </button>
                <button
                  onClick={() => onProfileChange(device.serial, 'production')}
                  className={`flex-1 p-4 rounded-lg border ${
                    device.profile === 'production'
                      ? 'border-blue-500 bg-blue-500/10'
                      : 'border-gray-800 hover:bg-white/5'
                  }`}
                >
                  <h4 className="font-medium mb-1">Production</h4>
                  <p className="text-sm text-gray-400">Optimized performance</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const UpdateModal = ({ onClose, selectedModel }) => {
  const [step, setStep] = useState(1);
  const [updateType, setUpdateType] = useState('all');
  const [selectedSerials, setSelectedSerials] = useState([]);
  const [version, setVersion] = useState('');
  const fileRef = useRef(null);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        className="bg-gray-900 rounded-xl p-6 max-w-xl w-full"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-start mb-6">
          <h2 className="text-2xl font-bold">Software Update</h2>
          <button onClick={onClose} className="p-2 hover:bg-white/5 rounded-lg">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-6">
          {step === 1 && (
            <div className="space-y-4">
              <div className="p-4 bg-blue-500/10 rounded-lg">
                <h3 className="font-semibold mb-2">{selectedModel.name}</h3>
                <p className="text-sm text-gray-400">Current Version: {selectedModel.version}</p>
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-2">New Version</label>
                <input
                  type="text"
                  value={version}
                  onChange={(e) => setVersion(e.target.value)}
                  className="w-full bg-black/50 border border-gray-800 rounded-lg px-4 py-2"
                  placeholder="e.g. 2.1.1"
                />
              </div>

              <div>
                <input
                  type="file"
                  ref={fileRef}
                  accept=".bin"
                  className="hidden"
                />
                <button
                  onClick={() => fileRef.current?.click()}
                  className="w-full border-2 border-dashed border-gray-800 rounded-lg p-4 hover:bg-white/5"
                >
                  <Upload className="w-6 h-6 mx-auto mb-2" />
                  <p className="text-sm text-gray-400">Select Firmware File (.bin)</p>
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="space-y-4">
              <div className="flex space-x-4">
                <button
                  onClick={() => setUpdateType('all')}
                  className={`flex-1 p-4 rounded-lg border ${
                    updateType === 'all'
                      ? 'border-blue-500 bg-blue-500/10'
                      : 'border-gray-800 hover:bg-white/5'
                  }`}
                >
                  <h4 className="font-medium mb-1">All Devices</h4>
                  <p className="text-sm text-gray-400">Update entire model group</p>
                </button>
                <button
                  onClick={() => setUpdateType('select')}
                  className={`flex-1 p-4 rounded-lg border ${
                    updateType === 'select'
                      ? 'border-blue-500 bg-blue-500/10'
                      : 'border-gray-800 hover:bg-white/5'
                  }`}
                >
                  <h4 className="font-medium mb-1">Select Devices</h4>
                  <p className="text-sm text-gray-400">Choose specific devices</p>
                </button>
              </div>

              {updateType === 'select' && (
                <div className="space-y-2">
                  {selectedModel.devices.map(device => (
                    <label
                      key={device.serial}
                      className="flex items-center p-3 rounded-lg hover:bg-white/5"
                    >
                      <input
                        type="checkbox"
                        checked={selectedSerials.includes(device.serial)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedSerials([...selectedSerials, device.serial]);
                          } else {
                            setSelectedSerials(selectedSerials.filter(s => s !== device.serial));
                          }
                        }}
                        className="mr-3"
                      />
                      <div>
                        <p className="font-medium">{device.serial}</p>
                        <p className="text-sm text-gray-400">Last update: {device.lastUpdate}</p>
                      </div>
                      <div className={`ml-auto px-2 py-1 rounded-full text-xs ${
                        device.status === 'Online'
                          ? 'bg-green-500/10 text-green-400'
                          : 'bg-red-500/10 text-red-400'
                      }`}>
                        {device.status}
                      </div>
                    </label>
                  ))}
                </div>
              )}
            </div>
          )}

          <div className="flex justify-between pt-4 border-t border-gray-800">
            {step > 1 && (
              <button
                onClick={() => setStep(step - 1)}
                className="px-4 py-2 text-gray-400 hover:text-white"
              >
                Back
              </button>
            )}
            <button
              onClick={() => step === 1 ? setStep(2) : onClose()}
              className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              {step === 1 ? 'Next' : 'Update'}
            </button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const DeviceManager = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedModel, setSelectedModel] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [devices, setDevices] = useState(mockDevices.models);

  const handleProfileChange = (serial, newProfile) => {
    setDevices(prevDevices => {
      return prevDevices.map(model => ({
        ...model,
        devices: model.devices.map(device =>
          device.serial === serial
            ? { ...device, profile: newProfile }
            : device
        )
      }));
    });
  };

  const filteredModels = devices.filter(model => {
    const search = searchQuery.toLowerCase();
    return (
      model.name.toLowerCase().includes(search) ||
      model.version.toLowerCase().includes(search) ||
      model.devices.some(device =>
        device.serial.toLowerCase().includes(search)
      )
    );
  });

  return (
    <div className="max-w-6xl mx-auto">
      <div className="mb-6 flex items-center justify-between">
        <div className="relative flex-1 max-w-xl">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search devices by model, serial number, or version..."
            className="w-full bg-black/50 border border-gray-800 rounded-lg pl-10 pr-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
          />
          <Search className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {filteredModels.map((model) => (
          <motion.div
            key={model.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-6 border border-gray-800"
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-xl font-semibold mb-1">{model.name}</h3>
                <p className="text-sm text-gray-400">Version {model.version}</p>
              </div>
              <button
                onClick={() => {
                  setSelectedModel(model);
                  setShowUpdateModal(true);
                }}
                className="px-4 py-2 bg-blue-500/10 text-blue-400 rounded-lg hover:bg-blue-500/20"
              >
                Update Software
              </button>
            </div>

            <div className="space-y-3">
              {model.devices.map((device) => (
                <div
                  key={device.serial}
                  className="flex items-center justify-between p-3 bg-black/30 rounded-lg cursor-pointer hover:bg-black/50"
                  onClick={() => setSelectedDevice(device)}
                >
                  <div>
                    <p className="font-medium">{device.serial}</p>
                    <p className="text-sm text-gray-400">Version: {device.softwareVersion}</p>
                    <p className="text-sm text-gray-400">Profile: {device.profile}</p>
                  </div>
                  <div className={`px-2 py-1 rounded-full text-xs ${
                    device.status === 'Online'
                      ? 'bg-green-500/10 text-green-400'
                      : 'bg-red-500/10 text-red-400'
                  }`}>
                    {device.status}
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        ))}
      </div>

      <AnimatePresence>
        {showUpdateModal && (
          <UpdateModal
            selectedModel={selectedModel}
            onClose={() => setShowUpdateModal(false)}
          />
        )}
        {selectedDevice && (
          <DeviceDetails
            device={selectedDevice}
            onClose={() => setSelectedDevice(null)}
            onProfileChange={handleProfileChange}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

const NotificationsPopover = ({ notifications, onClose }) => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0 }}
    className="absolute right-0 top-12 w-80 bg-gray-900 border border-gray-800 rounded-lg shadow-xl z-50"
  >
    <div className="p-4 border-b border-gray-800">
      <h3 className="font-semibold">Notifications</h3>
    </div>
    <div className="max-h-96 overflow-y-auto">
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className={`p-4 border-b border-gray-800 hover:bg-gray-800/50 cursor-pointer ${
            notification.unread ? 'bg-gray-800/20' : ''
          }`}
        >
          <div className="flex justify-between mb-1">
            <span className="font-medium">{notification.title}</span>
            <span className="text-xs text-gray-400">{notification.time}</span>
          </div>
          <p className="text-sm text-gray-400">{notification.message}</p>
        </div>
      ))}
    </div>
  </motion.div>
);

const DashboardCard = ({ title, children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3 }}
    className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-6 border border-gray-800"
  >
    <h3 className="text-lg font-semibold mb-4">{title}</h3>
    {children}
  </motion.div>
);

const Sidebar = ({ sidebarOpen, setSidebarOpen, activeTab, setActiveTab, tabs }) => {
  return (
    <>
      <AnimatePresence>
        {sidebarOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40 md:hidden"
            onClick={() => setSidebarOpen(false)}
          />
        )}
      </AnimatePresence>

      <motion.div
        initial={{ x: -256 }}
        animate={{ x: sidebarOpen ? 0 : -256 }}
        transition={{ type: "spring", damping: 20 }}
        className="fixed md:hidden inset-y-0 left-0 z-50 w-64"
      >
        <div className="h-full bg-gray-900/50 backdrop-blur-xl border-r border-gray-800 p-4">
          <div className="flex items-center justify-between mb-8">
            <img src={require('assets/images/phynux_logo.png')} className="w-8 h-8 rounded-full" alt="Profile" />
            <span className="text-2xl font-bold">Phynux</span>
            <button
              className="md:hidden p-2 hover:bg-white/5 rounded-lg"
              onClick={() => setSidebarOpen(false)}
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <nav className="space-y-2">
            {tabs.map((tab) => (
              <motion.button
                key={tab.id}
                className={`flex items-center w-full px-4 py-3 rounded-lg transition-colors relative ${
                  activeTab === tab.id
                    ? 'text-blue-500'
                    : 'text-gray-400 hover:text-white hover:bg-white/5'
                }`}
                onClick={() => {
                  setActiveTab(tab.id);
                  setSidebarOpen(false);
                }}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                {tab.icon && <tab.icon className="w-5 h-5 mr-3" />}
                {tab.text}
                {activeTab === tab.id && (
                  <motion.div
                    layoutId="activeTab"
                    className="absolute left-0 w-1 h-8 bg-blue-500 rounded-r"
                    transition={{ type: "spring", damping: 15 }}
                  />
                )}
              </motion.button>
            ))}
          </nav>
        </div>
      </motion.div>
    </>
  );
};

const SettingsTab = () => {
  const [activeSection, setActiveSection] = useState('profile');

  return (
    <div className="max-w-4xl mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {/* Settings Navigation */}
        <div className="space-y-1">
          {[
            { id: 'profile', label: 'Profile', icon: Users },
            { id: 'security', label: 'Security', icon: Settings },
            { id: 'notifications', label: 'Notifications', icon: Bell }
          ].map(section => (
            <button
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              className={`w-full text-left px-4 py-2 rounded-lg flex items-center space-x-2 ${
                activeSection === section.id
                  ? 'bg-blue-500/10 text-blue-500'
                  : 'text-gray-400 hover:bg-gray-800/50'
              }`}
            >
              <section.icon className="w-5 h-5" />
              <span>{section.label}</span>
            </button>
          ))}
        </div>

        {/* Settings Content */}
        <div className="md:col-span-3 space-y-6">
          <AnimatePresence mode="wait">
            {activeSection === 'profile' && (
              <motion.div
                key="profile"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                className="space-y-6"
              >
                <DashboardCard title="Profile Information">
                  <div className="flex items-start space-x-4">
                    <img src={mockUserData.avatar} alt="Profile" className="w-20 h-20 rounded-lg" />
                    <div className="flex-1 space-y-4">
                      <div>
                        <label className="block text-sm text-gray-400">Full Name</label>
                        <input
                          type="text"
                          value={mockUserData.name}
                          className="mt-1 w-full bg-black/50 border border-gray-800 rounded-lg px-4 py-2"
                        />
                      </div>
                      <div>
                        <label className="block text-sm text-gray-400">Email</label>
                        <input
                          type="email"
                          value={mockUserData.email}
                          className="mt-1 w-full bg-black/50 border border-gray-800 rounded-lg px-4 py-2"
                        />
                      </div>
                      <div>
                        <label className="block text-sm text-gray-400">Role</label>
                        <div className="mt-1 px-4 py-2 bg-blue-500/10 text-blue-400 rounded-lg inline-block">
                          {mockUserData.role}
                        </div>
                      </div>
                    </div>
                  </div>
                </DashboardCard>
              </motion.div>
            )}

            {activeSection === 'security' && (
              <motion.div
                key="security"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                className="space-y-6"
              >
                <DashboardCard title="Security Settings">
                  <div className="space-y-6">
                    <div className="flex items-center justify-between">
                      <div>
                        <h4 className="font-medium">Two-Factor Authentication</h4>
                        <p className="text-sm text-gray-400">Add an extra layer of security to your account</p>
                      </div>
                      <button className="px-4 py-2 bg-blue-500/10 text-blue-400 rounded-lg">
                        Enable
                      </button>
                    </div>
                    <div className="flex items-center justify-between">
                      <div>
                        <h4 className="font-medium">Change Password</h4>
                        <p className="text-sm text-gray-400">Update your password regularly</p>
                      </div>
                      <button className="px-4 py-2 bg-blue-500/10 text-blue-400 rounded-lg">
                        Update
                      </button>
                    </div>
                    <div className="pt-6 border-t border-gray-800">
                      <button className="px-4 py-2 bg-red-500/10 text-red-400 rounded-lg">
                        Logout
                      </button>
                    </div>
                  </div>
                </DashboardCard>

                <DashboardCard title="Login History">
                  <div className="space-y-4">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-sm">Last login</p>
                        <p className="text-gray-400">{new Date(mockUserData.lastLogin).toLocaleString()}</p>
                      </div>
                      <span className="px-2 py-1 bg-green-500/10 text-green-400 rounded-full text-xs">
                        Current Session
                      </span>
                    </div>
                  </div>
                </DashboardCard>
              </motion.div>
            )}

            {activeSection === 'notifications' && (
              <motion.div
                key="notifications"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                className="space-y-6"
              >
                <DashboardCard title="Notification Preferences">
                  <div className="space-y-4">
                    {[
                      { label: 'Push Notifications', description: 'Receive notifications in-app' },
                      { label: 'Email Updates', description: 'Get important updates via email' },
                      { label: 'Monthly Reports', description: 'Receive monthly activity reports' }
                    ].map((pref, index) => (
                      <div key={index} className="flex items-center justify-between">
                        <div>
                          <h4 className="font-medium">{pref.label}</h4>
                          <p className="text-sm text-gray-400">{pref.description}</p>
                        </div>
                        <button
                          className={`w-12 h-6 rounded-full flex items-center p-1 transition-colors ${
                            index === 0 ? 'bg-blue-500' : 'bg-gray-700'
                          }`}
                        >
                          <motion.div
                            className="w-4 h-4 bg-white rounded-full"
                            animate={{ x: index === 0 ? 24 : 0 }}
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                </DashboardCard>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [accessibleTabs, setAccessibleTabs] = useState([]);
  const [unreadCount, setUnreadCount] = useState(
    mockData.notifications.filter(n => n.unread).length
  );
  const navigation = useHistory();
  const jsonData = sessionStorage.getItem('user'); // Assume it's JSON
  const user = jsonData ? JSON.parse(jsonData) : null;

  useEffect(() => {

    // Redirect to login if user is null
    if (!user) {
      navigation.push('/login'); // Redirect to the login page
      return;
    }

    const user_access = user['accessRights'];

    const tabs = [
      { id: 'analytics', icon: BarChart2, text: 'Dashboard' },
      { id: 'customers', icon: Users, text: 'Clients' },
      { id: 'devices', icon: Wifi, text: 'Devices' },
      { id: 'blogs', icon: DockIcon, text: 'Blog' },
      { id: 'employees', icon: People, text: 'Staff' },
      { id: 'jobs', icon: Briefcase, text: 'Job Listings' },
      { id: 'settings', icon: Settings, text: 'Settings' }
    ];

    // Filter tabs based on user access rights
    const filteredTabs = tabs.filter(tab => user_access.includes(tab.id));
    setAccessibleTabs(filteredTabs);
  }, [navigation]); // Runs only once when the component mounts


  return (
    <>
    {user && (
      <div className="min-h-screen bg-black text-white flex">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={accessibleTabs}
      />

      <div className="flex-1">
        <header className="bg-gray-900/50 backdrop-blur-xl border-b border-gray-800 sticky top-0 z-30">
          <div className="flex items-center justify-between p-4">
            <button
              className="md:hidden p-2 hover:bg-white/5 rounded-lg"
              onClick={() => setSidebarOpen(true)}
            >
              <Menu className="w-6 h-6" />
            </button>

            <img src={require('assets/images/phynux_logo.png')} className="w-8 h-8 rounded-full" alt="Profile" onClick={() => (navigation.push('/'))} />

            <div className="flex items-center flex-1 px-4">
              <motion.div
                className="relative flex-1 max-w-xl"
                animate={{ scale: isSearchFocused ? 1.02 : 1 }}
              >
                Welcome, {user["firstName"]}
              </motion.div>
            </div>
            {/*
            <div className="justify-between items-center space-x-4">
            <div className="relative">
              <motion.button
                className="relative"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowNotifications(!showNotifications)}
              >
                <Bell className="w-6 h-6" />
                {unreadCount > 0 && (
                  <span className="absolute -top-1 -right-1 w-5 h-5 bg-blue-500 rounded-full flex items-center justify-center text-xs">
                    {unreadCount}
                  </span>
                )}
              </motion.button>
              <AnimatePresence>
                {showNotifications && (
                  <NotificationsPopover
                    notifications={mockData.notifications}
                    onClose={() => setShowNotifications(false)}
                  />
                )}
              </AnimatePresence>
            </div>
            </div>
            */}
            {user['image'] ? (
                <img
                src={user['image']}
                alt={'Profile'}
                className="w-8 h-8 rounded-full object-cover"
                />
            ) : (
                <div className="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center">
                <span className="text-sm font-medium">
                    {user['firstName'][0]}{user['lastName'][0]}
                </span>
                </div>
            )}
          </div>
        </header>

        <div className="hidden md:flex border-b border-gray-800 bg-gray-900/50 backdrop-blur-xl">
          {accessibleTabs.map((tab) => (
            <motion.button
              key={tab.id}
              className={`flex items-center px-6 py-4 relative ${
                activeTab === tab.id
                  ? 'text-blue-500'
                  : 'text-gray-400 hover:text-white'
              }`}
              onClick={() => setActiveTab(tab.id)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {tab.icon && <tab.icon className="w-5 h-5 mr-2" />}
              {tab.text}
              {activeTab === tab.id && (
                <motion.div
                  layoutId="activeTabDesktop"
                  className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500"
                />
              )}
            </motion.button>
          ))}
        </div>

        <main className="p-4 md:p-6">
          {activeTab === 'analytics' && (
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mb-6">
                  {[
                    { title: 'Balance', value: '$12', icon: MonetizationOn },
                    //{ title: 'Team Members', value: '24', icon: Users },
                    //{ title: 'Hours Logged', value: '164', icon: Clock }
                  ].map((stat, index) => (
                    <motion.div
                      key={stat.title}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      <DashboardCard>
                        <div className="flex items-center">
                          <div className="flex-1">
                            <p className="text-gray-400 mb-1">{stat.title}</p>
                            <motion.h4
                              className="text-2xl font-bold"
                              initial={{ scale: 0 }}
                              animate={{ scale: 1 }}
                              transition={{ type: 'spring', delay: 0.2 + index * 0.1 }}
                            >
                              {stat.value}
                            </motion.h4>
                          </div>
                          {stat.icon && (
                            <motion.div
                              initial={{ rotate: -30, opacity: 0 }}
                              animate={{ rotate: 0, opacity: 0.8 }}
                              transition={{ delay: 0.3 + index * 0.1 }}
                            >
                              <stat.icon className="w-8 h-8 text-blue-500" />
                            </motion.div>
                          )}
                        </div>
                      </DashboardCard>
                    </motion.div>
                  ))}
                </div>
              <DashboardCard title="Revenue Overview">
                  <div className="h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={mockData.revenueData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                        <XAxis dataKey="name" stroke="#9CA3AF" />
                        <YAxis stroke="#9CA3AF" />
                        <Tooltip contentStyle={{ backgroundColor: '#111827', border: 'none' }} />
                        <Line type="monotone" dataKey="value" stroke="#3B82F6" strokeWidth={2} />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </DashboardCard>
            </div>
          )}

          {activeTab === 'customers' && (
            <ClientsList />
          )}
          {activeTab === 'settings' && (
            <SettingsTab />
          )}
          {activeTab === 'devices' && (
            <DeviceManager />
          )}
          {activeTab === 'blogs' && (
            <BlogManagement />
          )}
          {activeTab === 'employees' && (
            <StaffManagement />
          )}
          {activeTab === 'jobs' && (
            <JobsList />
          )}
        </main>
      </div>
    </div>
    )}
    </>
  );
};

export default Dashboard;