
import { Route, Switch } from "react-router-dom";
import Jobs from './layouts/jobs'
import Login from './layouts/login'
import PassRest from './layouts/login/comps/password_reset'
import Blog from './layouts/blog'
import Dashboard from './layouts/dashboard'
import BlogReader from './layouts/blog/reader'
import Home from './layouts/webpages/phynux_home'

export default function App() {

  return (
      <Switch>
        <Route exact path='/' component={Home} key={'home'} />
        <Route exact path='/login' component={Login} key={'login'} />
        <Route exact path='/dashboard' component={Dashboard} key={'dashboard'} />
        <Route path="/reset-password/:token" component={PassRest} />
        <Route exact path='/blog' component={Blog} key={'blog'} />
        <Route path="/blog/:id" component={BlogReader} />
        <Route exact path='/jobs' component={Jobs} key={'jobs'} />
      </Switch>
  );
}
