const API_URL = 'https://www.phynux.tech/api';

// Image processing utilities
const processImage = async (file, maxWidth = 1920, maxHeight = 1080, quality = 0.8) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height = (height * maxWidth) / width;
          width = maxWidth;
        }
        if (height > maxHeight) {
          width = (width * maxHeight) / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, width, height);
        ctx.drawImage(img, 0, 0, width, height);

        const base64 = canvas.toDataURL('image/jpeg', quality);
        resolve(base64);
      };
    };
  });
};

const createFormData = async (blogData) => {
  const formData = new FormData();

  // Add all base fields
  for (const [key, value] of Object.entries(blogData)) {
    if (key === 'tags' || key === 'image') continue; // Handle these separately
    formData.append(key, value);
  }

  // Handle tags
  if (blogData.tags) {
    blogData.tags.forEach(tag => {
      formData.append('tags[]', tag);
    });
  }

  // Handle image
  if (blogData.image) {
    if (blogData.image instanceof File) {
      // If it's a file, process it to base64
      const base64Image = await processImage(blogData.image);
      formData.append('image', base64Image);
    } else if (typeof blogData.image === 'string' && blogData.image.startsWith('data:image')) {
      // If it's already base64, add it directly
      formData.append('image', blogData.image);
    }
  }

  return formData;
};

export const blogService = {
  async getBlogs() {
    const response = await fetch(`${API_URL}/blogs`);
    if (!response.ok) throw new Error('Failed to fetch blogs');
    return response.json();
  },

  async getBlog(id) {
    const response = await fetch(`${API_URL}/blogs/${id}`);
    if (!response.ok) throw new Error('Failed to fetch blog');
    return response.json();
  },

  async createBlog(blogData) {
    const formData = await createFormData(blogData);

    const response = await fetch(`${API_URL}/blogs`, {
      method: 'POST',
      body: formData // Send as FormData, no Content-Type header needed
    });

    if (!response.ok) throw new Error('Failed to create blog');
    return response.json();
  },

  async updateBlog(id, blogData) {
    const formData = await createFormData(blogData);

    const response = await fetch(`${API_URL}/blogs/${id}`, {
      method: 'PUT',
      body: formData // Send as FormData, no Content-Type header needed
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to update blog');
    }

    return response.json();
  },

  async deleteBlog(id) {
    const response = await fetch(`${API_URL}/blogs/${id}`, {
      method: 'DELETE'
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || 'Failed to delete blog');
    }

    return data;
  },

  processImage
};