import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Eye, EyeOff, ArrowLeft, Lock, User, AlertCircle } from 'lucide-react';
import { useHistory, useLocation } from 'react-router-dom';
import { authService } from './backendservice/authService';

const EmployeeLogin = () => {
  const [employeeId, setEmployeeId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showResetForm, setShowResetForm] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const navigation = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Check if user is already logged in
    if (authService.isAuthenticated()) {
      const redirectPath = location.state?.from || '/dashboard';
      navigation.replace(redirectPath);
    }
  }, [navigation, location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      const response = await authService.login(employeeId, password, rememberMe);
      const redirectPath = location.state?.from || '/dashboard';
      navigation.replace(redirectPath);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      await authService.requestPasswordReset(employeeId);
      setResetSuccess(true);
      setTimeout(() => {
        setShowResetForm(false);
        setResetSuccess(false);
      }, 3000);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white flex items-center justify-center relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900/20 via-black to-purple-900/20" />
      <div className="absolute top-20 left-20 w-64 h-64 bg-blue-500/10 rounded-full blur-3xl" />
      <div className="absolute bottom-20 right-20 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl" />

      {/* Back Button */}
      <motion.button
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        className="absolute top-8 left-8 text-gray-400 hover:text-white flex items-center gap-2 transition-colors"
        onClick={() => navigation.push('/')}
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Home
      </motion.button>

      {/* Login Container */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="relative z-10 w-full max-w-md mx-4"
      >
        {/* Logo and Title */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold mb-2 hero-title">Employee Portal</h1>
          <p className="text-gray-400">Access your Phynux workspace</p>
        </div>

        {/* Error Message */}
        {/* Error Message */}
        {error && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-red-500/20 text-red-400 px-4 py-3 rounded-lg flex items-center gap-2 mb-6"
          >
            <AlertCircle className="w-5 h-5" />
            {error}
          </motion.div>
        )}

        {/* Success Message for Password Reset */}
        {resetSuccess && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-green-500/20 text-green-400 px-4 py-3 rounded-lg flex items-center gap-2 mb-6"
          >
            <AlertCircle className="w-5 h-5" />
            Password reset instructions have been sent to your email.
          </motion.div>
        )}

        {/* Login/Reset Password Form */}
        <div className="bg-gray-900/50 backdrop-blur-xl rounded-2xl p-8 shadow-xl border border-gray-800">
          <form onSubmit={showResetForm ? handlePasswordReset : handleSubmit} className="space-y-6">
            {/* Employee ID Field */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-300">
                Employee ID
              </label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  required
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  className="w-full bg-gray-800/50 border border-gray-700 rounded-lg py-3 pl-10 pr-4 text-white placeholder-gray-400 focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 transition-all"
                  placeholder="Enter your employee ID"
                />
              </div>
            </div>

            {/* Password Field - Only show if not in reset mode */}
            {!showResetForm && (
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-300">
                  Password
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    type={showPassword ? "text" : "password"}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full bg-gray-800/50 border border-gray-700 rounded-lg py-3 pl-10 pr-12 text-white placeholder-gray-400 focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 transition-all"
                    placeholder="Enter your password"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-300"
                  >
                    {showPassword ? (
                      <EyeOff className="w-5 h-5" />
                    ) : (
                      <Eye className="w-5 h-5" />
                    )}
                  </button>
                </div>
              </div>
            )}

            {/* Remember Me & Forgot Password - Only show if not in reset mode */}
            {!showResetForm && (
              <div className="flex items-center justify-between">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    className="rounded bg-gray-800 border-gray-700 text-blue-500 focus:ring-blue-500/20"
                  />
                  <span className="ml-2 text-sm text-gray-300">Keep me signed in</span>
                </label>
                <button
                  type="button"
                  onClick={() => setShowResetForm(true)}
                  className="text-sm text-blue-400 hover:text-blue-300 transition-colors"
                >
                  Reset password
                </button>
              </div>
            )}

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isLoading}
              className="w-full bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-600 text-white rounded-lg py-3 font-medium transition-all relative overflow-hidden group disabled:opacity-50"
            >
              <span className={`transition-all ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
                {showResetForm ? 'Send Reset Instructions' : 'Sign In'}
              </span>
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                </div>
              )}
            </button>

            {/* Form Toggle */}
            <div className="text-center">
              <button
                type="button"
                onClick={() => {
                  setShowResetForm(!showResetForm);
                  setError(null);
                }}
                className="text-sm text-gray-400 hover:text-white transition-colors"
              >
                {showResetForm ? 'Back to Sign In' : 'Having trouble signing in?'}
              </button>
            </div>
          </form>
        </div>

        {/* Help Section */}
        <div className="mt-8 text-center space-y-4">
          <p className="text-sm text-gray-400">Need help?</p>
          <div className="flex justify-center gap-6">
            <button 
              className="text-sm text-gray-400 hover:text-white transition-colors"
              onClick={() => navigation.push('/support')}
            >
              Contact Support
            </button>
            <button 
              className="text-sm text-gray-400 hover:text-white transition-colors"
              onClick={() => window.open('docs/employee-guide.pdf')}
            >
              View Guide
            </button>
          </div>
        </div>

        {/* Security Note */}
        <div className="mt-8 text-center text-sm text-gray-400">
          <Lock className="inline-block w-4 h-4 mr-1 mb-1" />
          Secure employee access portal
        </div>
      </motion.div>

      {/* System Status */}
      <div className="absolute bottom-4 left-4 flex items-center gap-2">
        <div className="w-2 h-2 rounded-full bg-green-400" />
        <span className="text-sm text-gray-400">All systems operational</span>
      </div>
    </div>
  );
};

export default EmployeeLogin;