import React, { useState, useEffect } from 'react';
import { ChevronRight, Menu, X, ArrowRight, Users, Shield, Cpu, Globe } from 'lucide-react';
import './Phynux.css';
import { useHistory } from 'react-router-dom';

export default function PhynuxLanding() {
  const [scrollY, setScrollY] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigation = useHistory().push;

  useEffect(() => {
    const styleSheet = document.createElement("style");
    document.head.appendChild(styleSheet);

    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);

    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      styleSheet.remove();
      document.body.style.overflow = 'unset';
    };
  }, [menuOpen]);

  const services = [
    {
      title: "Forged-Fitness",
      description: "Revolutionary gym management platform designed to transform the fitness industry. Our comprehensive solution streamlines operations, enhances member engagement, and optimizes facility management with cutting-edge technology.",
      features: [
        "Advanced Member Management",
        "Real-time Analytics Dashboard",
        "Automated Billing System",
        "Class Scheduling & Booking"
      ],
      image: require("assets/images/forgedfitnessLogo.png"),
      color: "from-purple-600 to-blue-600",
      goto: '/forged-fitness'
    }
  ];

  const stats = [
    {
      title: "Trusted by Industry Leaders",
      description: "Partnering with top organizations worldwide to deliver exceptional solutions",
      icon: <Users className="w-8 h-8 mb-4 text-blue-400" />
    },
    {
      title: "Scalable & Secure Systems",
      description: "Enterprise-grade infrastructure built for reliability and growth",
      icon: <Shield className="w-8 h-8 mb-4 text-blue-400" />
    },
    {
      title: "Cutting-Edge Solutions",
      description: "Leveraging latest technologies to solve complex challenges",
      icon: <Cpu className="w-8 h-8 mb-4 text-blue-400" />
    }
  ];

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Navigation */}
      <nav className={`fixed w-full z-50 transition-all duration-300 ${
        scrollY > 20 ? 'nav-blur bg-black/50' : ''
      }`}>
        <div className="container mx-auto px-6">
          <div className="flex items-center justify-between h-16">
            <div className="flex flex-col md:flex-row items-center justify-center text-center space-y-4 md:space-y-0">
                <img src={require('assets/images/phynux_logo.png')} className="w-8 h-8 rounded-full" alt="Profile" />
                <div className="hidden md:block text-2xl font-bold hero-title md:ml-4">Phynux</div>
            </div>   
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              {/*['Products', 'Services', 'Solutions', 'Support'].map((item) => (
                <button key={item} className="text-sm font-medium hover:text-blue-400 transition-colors">
                  {item}
                </button>
              ))*/}
              <a 
                href='#services'
                className="text-sm font-medium hover:text-blue-400 transition-colors"
              >
                Services
              </a>
              <button 
                onClick={() => navigation('/blog')} 
                className="text-sm font-medium hover:text-blue-400 transition-colors"
              >
                Blog
              </button>
              <button 
                onClick={() => navigation('/jobs')} 
                className="text-sm font-medium hover:text-blue-400 transition-colors"
              >
                Careers
              </button>
              <button 
                onClick={() => navigation('/login')}
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-full text-sm font-medium transition-colors"
              >
                Login
              </button>
            </div>
            
            {/* Mobile Menu Button */}
            <button 
              className="md:hidden button-primary p-2 rounded-full" 
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? <X /> : <Menu />}
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      <div className={`fixed inset-0 z-40 mobile-menu ${menuOpen ? 'open' : ''}`}>
        <div className="absolute inset-0 bg-black/95 backdrop-blur-xl">
          <div className="flex flex-col items-center justify-center h-full space-y-8">
              <a 
                href='#services'
                onClick={() => setMenuOpen(false)}
                className="mobile-menu-item text-3xl font-medium hover:text-blue-400 transition-colors"
              >
                Services
              </a>
            {['Blog', 'Careers'].map((item, index) => (
              <button 
                key={item} 
                className="mobile-menu-item text-3xl font-medium hover:text-blue-400 transition-colors"
                style={{transitionDelay: `${index * 100}ms`}}
                onClick={() => {
                  setMenuOpen(false);
                  if (item === 'Careers') navigation('/jobs');
                  if (item === 'Blog') navigation('/blog');
                }}
              >
                {item}
              </button>
            ))}
            <button 
              className="mobile-menu-item px-6 py-2 bg-blue-600 hover:bg-blue-700 rounded-full text-xl font-medium transition-colors"
              onClick={() => {
                setMenuOpen(false);
                navigation('/login');
              }}
            >
              Login
            </button>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <section className="relative h-screen flex items-center justify-center overflow-hidden hero-bg">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-900/20 to-black/50" />
        <div className="relative z-10 text-center max-w-4xl mx-auto px-6" style={{animation: 'fadeIn 1s ease-out'}}>
          <h1 className="text-7xl font-bold mb-6 hero-title">
            Phynux
          </h1>
          <p className="text-2xl text-gray-300 mb-8 leading-relaxed">
            Pioneering innovative solutions through advanced technology and exceptional engineering. 
            We transform complex challenges into seamless experiences.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <a href='#about' className="button-primary px-8 py-4 rounded-full text-lg font-medium">
              Discover Phynux
            </a>
          </div>
        </div>
        <div className="scroll-indicator" />
      </section>

      {/* About Section */}
      <section id='about' className="py-24 bg-gradient-to-b from-black to-gray-900">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h2 className="text-4xl font-bold mb-6 hero-title">Transforming Industries Through Technology</h2>
            <p className="text-xl text-gray-400">
              At Phynux, we combine cutting-edge technology with innovative thinking to create solutions 
              that drive business growth and operational excellence. Our commitment to quality and 
              innovation sets new standards in software engineering.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: <Globe className="w-12 h-12 text-blue-400" />,
                title: "Global Reach",
                description: "Serving clients worldwide with scalable solutions"
              },
              {
                icon: <Shield className="w-12 h-12 text-blue-400" />,
                title: "Enterprise Security",
                description: "Bank-grade security for peace of mind"
              },
              {
                icon: <Users className="w-12 h-12 text-blue-400" />,
                title: "Expert Support",
                description: "24/7 dedicated technical assistance"
              }
            ].map((item) => (
              <div key={item.title} className="p-6 rounded-xl bg-gray-800/50 backdrop-blur-sm">
                <div className="mb-4">{item.icon}</div>
                <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                <p className="text-gray-400">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-32" id='services'>
        <h4 className="text-5xl font-bold m-6 hero-title" >Services</h4>
        {services.map((service, index) => (
          <div key={service.title} className="service-card mx-6 mb-32 rounded-2xl p-8">
            <div className={`flex flex-col md:flex-row items-center ${
              index % 2 === 0 ? 'md:flex-row-reverse' : ''
            } gap-12`}>
              <div className="w-full md:w-1/2">
                <div className="image-container rounded-2xl overflow-hidden">
                  <img 
                    src={service.image} 
                    alt={service.title}
                    className="w-full h-auto transform transition-all duration-500"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 p-6">
                <h2 className="text-5xl font-bold mb-6 hero-title">{service.title}</h2>
                <p className="text-xl text-gray-400 mb-8">{service.description}</p>
                <div className="mb-8">
                  <h4 className="text-lg font-semibold mb-4">Key Features:</h4>
                  <ul className="space-y-2">
                    {service.features.map((feature) => (
                      <li key={feature} className="flex items-center gap-2 text-gray-300">
                        <ChevronRight className="w-4 h-4 text-blue-400" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <button 
                  className="button-primary px-6 py-3 rounded-full flex items-center gap-2" 
                  onClick={() => navigation(service.goto)}
                >
                  Learn more <ChevronRight className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </section>

      {/* Innovation Section */}
      <section className="relative py-32 overflow-hidden bg-gradient-to-b from-gray-900 to-black">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-6xl font-bold mb-8 hero-title">Innovation First</h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto mb-12">
            Pushing the boundaries of what's possible with cutting-edge technology and unparalleled expertise.
            Our commitment to innovation drives everything we do.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {stats.map((stat) => (
              <div key={stat.title} className="stats-container p-8 rounded-2xl service-card text-center">
                {stat.icon}
                <p className="text-2xl font-bold hero-title mb-4">{stat.title}</p>
                <p className="text-gray-400">{stat.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section 
      <section className="py-24 bg-gradient-to-r from-blue-900 to-purple-900">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-6">Ready to Transform Your Business?</h2>
          <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
            Join the growing number of businesses that trust Phynux for their technology needs.
            Let's build something amazing together.
          </p>
          <button className="px-8 py-4 bg-white text-blue-900 rounded-full text-lg font-medium hover:bg-gray-100 transition-colors">
            Get Started Today
          </button>
        </div>
      </section>*/}

      {/* Footer */}
      <footer className="footer-gradient">
        <div className="container mx-auto px-6 py-12">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
            {[
              {
                title: 'About Phynux',
                items: [
                  { name: 'Careers', path: '/jobs' },
                  { name: 'Blog', path: '/blog' },
                  //{ name: 'Contact', path: '/contact' }
                ]
              },
              /*{
                title: 'Services',
                items: [
                  { name: 'Support', path: '/support' }
                ]
              },
              {
                title: 'Resources',
                items: [
                  //{ name: 'Documentation', path: '/docs' },
                  //{ name: 'API', path: '/api' },
                  //{ name: 'Status', path: '/status' },
                  { name: 'Blog', path: '/blog' }
                ]
              },*/
              /*{
                title: 'Legal',
                items: [
                  { name: 'Privacy', path: '/privacy' },
                  { name: 'Terms', path: '/terms' },
                  { name: 'Security', path: '/security' },
                  { name: 'Compliance', path: '/compliance' }
                ]
              }*/
            ].map((section) => (
              <div key={section.title}>
                <h3 className="text-lg font-bold mb-4">{section.title}</h3>
                <div className="space-y-2">
                  {section.items.map((item) => (
                    <button 
                      key={item.name} 
                      className="block text-gray-400 hover:text-white transition-colors"
                      onClick={() => navigation(item.path)}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>© 2024 Phynux. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}