const API_URL = 'https://www.phynux.tech/api';

// Image processing utilities
const processImage = async (file, maxWidth = 800, maxHeight = 800, quality = 0.8) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Maintain aspect ratio while resizing
        if (width > maxWidth) {
          height = (height * maxWidth) / width;
          width = maxWidth;
        }
        if (height > maxHeight) {
          width = (width * maxHeight) / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');

        // Fill with white background to prevent transparency issues
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, width, height);
        ctx.drawImage(img, 0, 0, width, height);

        const base64 = canvas.toDataURL('image/jpeg', quality);
        resolve(base64);
      };
    };
  });
};

const createEmployeeData = async (employeeData) => {
  // For employees, we'll use JSON instead of FormData since we're not dealing with files
  // in the same way as blogs (no need for multiple tags[], etc.)
  const processedData = { ...employeeData };

  // Handle image processing
  if (employeeData.image) {
    if (employeeData.image instanceof File) {
      // If it's a file, process it to base64
      processedData.image = await processImage(employeeData.image);
    } else if (typeof employeeData.image === 'string' && !employeeData.image.startsWith('data:image')) {
      // If it's a URL but not base64, remove it to prevent errors
      delete processedData.image;
    }
  }

  // Ensure access rights is always an array
  if (!processedData.accessRights) {
    processedData.accessRights = [];
  }

  // Format dates properly
  if (processedData.startDate) {
    processedData.startDate = new Date(processedData.startDate).toISOString().split('T')[0];
  }

  return processedData;
};

export const employeeService = {
  async getEmployees() {
    const response = await fetch(`${API_URL}/employees`);
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to fetch employees');
    }
    return response.json();
  },

  async getEmployee(id) {
    const response = await fetch(`${API_URL}/employees/${id}`);
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to fetch employee');
    }
    return response.json();
  },

  async createEmployee(employeeData) {
    const processedData = await createEmployeeData(employeeData);

    const response = await fetch(`${API_URL}/employees`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(processedData)
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to create employee');
    }
    return response.json();
  },

  async updateEmployee(id, employeeData) {
    const processedData = await createEmployeeData(employeeData);

    const response = await fetch(`${API_URL}/employees/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(processedData)
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to update employee');
    }
    return response.json();
  },

  async deleteEmployee(id) {
    const response = await fetch(`${API_URL}/employees/${id}`, {
      method: 'DELETE'
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to delete employee');
    }
    return response.json();
  },

  // Helper method for password management
  async updateEmployeePassword(id, currentPassword, newPassword) {
    const response = await fetch(`${API_URL}/employees/${id}/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        currentPassword,
        newPassword
      })
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to update password');
    }
    return response.json();
  },

  // Helper method for handling employee status changes
  async updateEmployeeStatus(id, status) {
    const response = await fetch(`${API_URL}/employees/${id}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status })
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to update employee status');
    }
    return response.json();
  },

  // Export image processing utility in case it's needed elsewhere
  processImage
};