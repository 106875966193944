import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { 
  Upload, 
  X, 
  PlusCircle,
  Image as ImageIcon,
  Save,
  Tag as TagIcon,
  Calendar,
  Clock,
  CheckCircle,
  AlertCircle,
  List,
  PenSquare,
  Search,
  Edit,
  Trash2,
  MoreVertical,
  Crop
} from 'lucide-react';
import { blogService } from '../backendservice/blogService';
import ReactCrop from 'react-image-crop';


const ImageUploader = ({ onImageSelect, previewImage, onImageClear }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files[0]) {
      handleFileSelect(files[0]);
    }
  };

  const handleFileSelect = async (file) => {
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onImageSelect(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropComplete = (crop, percentCrop) => {
    setCompletedCrop(crop);
  };

  const handleCropImage = async () => {
    if (!completedCrop || !imgRef.current) return;

    const canvas = document.createElement('canvas');
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      imgRef.current,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );

    const base64Image = await blogService.processCroppedImage(canvas);
    onImageSelect({ base64: base64Image });
    setIsCropping(false);
  };

  return (
    <div className="space-y-4">
      {isCropping && previewImage ? (
        <div className="space-y-4">
          <ReactCrop
            crop={crop}
            onChange={newCrop => setCrop(newCrop)}
            onComplete={handleCropComplete}
            aspect={16 / 9}
          >
            <img ref={imgRef} src={typeof previewImage === 'string' ? previewImage : URL.createObjectURL(previewImage)} />
          </ReactCrop>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={() => setIsCropping(false)}
              className="px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleCropImage}
              className="px-4 py-2 bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Apply Crop
            </button>
          </div>
        </div>
      ) : (
        <div className="relative">
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileSelect(e.target.files[0])}
            className="hidden"
            id="image-upload"
          />
          {previewImage ? (
            <div className="relative rounded-lg overflow-hidden">
              <img
                src={typeof previewImage === 'string' ? previewImage : URL.createObjectURL(previewImage)}
                alt="Preview"
                className="w-full h-48 object-cover"
              />
              <div className="absolute top-2 right-2 flex gap-2">
                <button
                  type="button"
                  onClick={() => setIsCropping(true)}
                  className="p-1 bg-black/50 rounded-full hover:bg-black/70 transition-colors"
                >
                  <Crop className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    onImageClear();
                    setIsCropping(false);
                  }}
                  className="p-1 bg-black/50 rounded-full hover:bg-black/70 transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            </div>
          ) : (
            <label
              htmlFor="image-upload"
              className={`flex flex-col items-center justify-center w-full h-48 border-2 border-dashed rounded-lg cursor-pointer transition-colors ${
                isDragging
                  ? 'border-blue-500 bg-blue-500/10'
                  : 'border-gray-800 hover:border-blue-500/50'
              }`}
              onDragEnter={handleDragIn}
              onDragLeave={handleDragOut}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <motion.div
                animate={isDragging ? { scale: 1.1 } : { scale: 1 }}
                className="flex flex-col items-center"
              >
                {isDragging ? (
                  <Upload className="w-8 h-8 text-blue-400 mb-2" />
                ) : (
                  <ImageIcon className="w-8 h-8 text-gray-400 mb-2" />
                )}
                <span className="text-sm text-gray-400">
                  {isDragging ? 'Drop image here' : 'Click or drag image to upload'}
                </span>
              </motion.div>
            </label>
          )}
        </div>
      )}
    </div>
  );
};

const DropdownMenu = ({ children, items }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
      >
        {children}
      </button>
      {isOpen && (
        <>
          <div 
            className="fixed inset-0 z-10"
            onClick={() => setIsOpen(false)}
          />
          <div className="absolute right-0 mt-2 w-48 bg-gray-900 border border-gray-800 rounded-lg shadow-lg overflow-hidden z-20">
            {items.map((item, index) => (
              <button
                key={index}
                onClick={() => {
                  item.onClick();
                  setIsOpen(false);
                }}
                className={`w-full flex items-center px-4 py-2 text-sm ${item.className} hover:bg-gray-800`}
              >
                {item.icon}
                <span className="ml-2">{item.label}</span>
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const BlogManagement = ({ editBlog = null, onSuccess }) => {
  const [formData, setFormData] = useState({
    title: '',
    excerpt: '',
    content: '',
    category: '',
    readTime: '',
    tags: [],
    featured: false,
    image: null,
    status: 'Draft'
  });

  const [currentTag, setCurrentTag] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);

  const categories = [
    "Technology",
    "Case Study",
    "Engineering",
    "Product Updates",
    "Company News"
  ];

  useEffect(() => {
    if (editBlog) {
      setFormData({
        title: editBlog.title || '',
        excerpt: editBlog.excerpt || '',
        content: editBlog.content || '',
        category: editBlog.category || '',
        readTime: editBlog.read_time || '',
        tags: editBlog.tags || [],
        featured: editBlog.featured || false,
        status: editBlog.status || 'Draft',
        image: null
      });
      if (editBlog.image_url) {
        setPreviewImage(editBlog.image_url);
      }
    }
  }, [editBlog]);

  const handleImageUpload = (imageData) => {
    if (imageData.base64) {
      // Handle cropped image
      setFormData({ ...formData, image: imageData.base64 });
      setPreviewImage(imageData.base64);
    } else {
      // Handle regular file upload
      setFormData({ ...formData, image: imageData });
      setPreviewImage(imageData);
    }
  };

  const handleAddTag = (e) => {
    e.preventDefault();
    if (currentTag && !formData.tags.includes(currentTag)) {
      setFormData({
        ...formData,
        tags: [...formData.tags, currentTag]
      });
      setCurrentTag('');
    }
  };

  const removeTag = (tagToRemove) => {
    setFormData({
      ...formData,
      tags: formData.tags.filter(tag => tag !== tagToRemove)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    setError(null);

    try {
      if (editBlog) {
        await blogService.updateBlog(editBlog.id, formData);
      } else {
        await blogService.createBlog(formData);
      }
      
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        if (onSuccess) onSuccess();
      }, 1500);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="bg-red-500/20 text-red-400 px-4 py-3 rounded-lg flex items-center gap-2">
          <AlertCircle className="w-5 h-5" />
          {error}
        </div>
      )}

      {showSuccess && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-green-500/20 text-green-400 px-4 py-3 rounded-lg flex items-center gap-2"
        >
          <CheckCircle className="w-5 h-5" />
          Post {editBlog ? 'updated' : 'created'} successfully!
        </motion.div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          {/* Title */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Post Title *
            </label>
            <input
              type="text"
              required
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Enter post title"
            />
          </div>

          {/* Excerpt */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Excerpt *
            </label>
            <textarea
              required
              value={formData.excerpt}
              onChange={(e) => setFormData({ ...formData, excerpt: e.target.value })}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent h-24"
              placeholder="Brief description of the post"
            />
          </div>

          {/* Content */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Content *
            </label>
            <textarea
              required
              value={formData.content}
              onChange={(e) => setFormData({ ...formData, content: e.target.value })}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent h-96"
              placeholder="Write your post content here..."
            />
          </div>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Save Button */}
          <button
            type="submit"
            disabled={isSaving}
            className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors disabled:opacity-50"
          >
            {isSaving ? (
              <>
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                Saving...
              </>
            ) : (
              <>
                <Save className="w-5 h-5" />
                {editBlog ? 'Update Post' : 'Publish Post'}
              </>
            )}
          </button>

          {/* Featured Image */}
          <ImageUploader 
            onImageSelect={handleImageUpload}
            previewImage={previewImage}
            onImageClear={() => {
              setPreviewImage(null);
              setFormData({ ...formData, image: null });
            }}
          />

          {/* Category */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Category *
            </label>
            <select
              required
              value={formData.category}
              onChange={(e) => setFormData({ ...formData, category: e.target.value })}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="">Select category</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          {/* Status */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Status
            </label>
            <select
              value={formData.status}
              onChange={(e) => setFormData({ ...formData, status: e.target.value })}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="Draft">Draft</option>
              <option value="Published">Published</option>
            </select>
          </div>

          {/* Read Time */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Read Time *
            </label>
            <input
              type="text"
              required
              value={formData.readTime}
              onChange={(e) => setFormData({ ...formData, readTime: e.target.value })}
              className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="e.g. 5 min"
            />
          </div>

          {/* Tags */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Tags
            </label>
            <div className="space-y-3">
              <div className="flex gap-2">
                <input
                  type="text"
                  value={currentTag}
                  onChange={(e) => setCurrentTag(e.target.value)}
                  className="flex-1 bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Add a tag"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddTag(e);
                    }
                  }}
                />
                <button
                  type="button"
                  onClick={handleAddTag}
                  className="p-3 bg-gray-900/50 border border-gray-800 rounded-lg hover:bg-gray-800 transition-colors"
                >
                  <PlusCircle className="w-5 h-5" />
                </button>
              </div>
              <div className="flex flex-wrap gap-2">
                {formData.tags.map((tag) => (
                  <span
                    key={tag}
                    className="flex items-center gap-1 px-3 py-1 bg-gray-900/50 border border-gray-800 rounded-full text-sm"
                  >
                    {tag}
                    <button
                      type="button"
                      onClick={() => removeTag(tag)}
                      className="hover:text-red-400"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>

          {/* Featured Toggle */}
          <div className="flex items-center gap-3">
            <input
              type="checkbox"
              id="featured"
              checked={formData.featured}
              onChange={(e) => setFormData({ ...formData, featured: e.target.checked })}
              className="w-4 h-4 rounded bg-gray-900 border-gray-800 text-blue-500 focus:ring-blue-500/20"
            />
            <label htmlFor="featured" className="text-sm text-gray-300">
              Mark as featured post
            </label>
          </div>
        </div>
      </div>
    </form>
  );
};


const BlogDashboard = () => {
  const [activeTab, setActiveTab] = useState('list');
  const [searchQuery, setSearchQuery] = useState('');
  const [editingBlog, setEditingBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccessMessage] = useState(null);

  // Fetch blogs on component mount
  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      setIsLoading(true);
      const data = await blogService.getBlogs();
      setBlogs(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch blogs. Please try again later.');
      console.error('Error fetching blogs:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (blog) => {
    try {
      const blogData = await blogService.getBlog(blog.id);
      setEditingBlog(blogData);
      setActiveTab('edit');
    } catch (err) {
      console.error('Error fetching blog details:', err);
    }
  };

  const handleDelete = async (blogId) => {
    if (window.confirm('Are you sure you want to delete this blog post?')) {
      try {
        // Show loading state
        setIsLoading(true);
        
        const result = await blogService.deleteBlog(blogId);
        
        // Update local state
        setBlogs(prevBlogs => prevBlogs.filter(blog => blog.id !== blogId));
        
        // Show success message
        setSuccessMessage('Blog deleted successfully');
        setTimeout(() => setSuccessMessage(null), 3000);
        
      } catch (err) {
        console.error('Error deleting blog:', err);
        setError(err.message || 'Failed to delete blog');
        setTimeout(() => setError(null), 3000);
      } finally {
        setIsLoading(false);
      }
    }};

  const filteredBlogs = blogs.filter(blog =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    blog.category.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Mobile action menu component
  const BlogActions = ({ blog }) => (
    <DropdownMenu
      items={[
        {
          icon: <Edit className="w-4 h-4" />,
          label: 'Edit',
          onClick: () => handleEdit(blog),
          className: 'text-blue-400'
        },
        {
          icon: <Trash2 className="w-4 h-4" />,
          label: 'Delete',
          onClick: () => handleDelete(blog.id),
          className: 'text-red-400'
        }
      ]}
    >
      <MoreVertical className="w-5 h-5" />
    </DropdownMenu>
  );

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-4 sm:py-8">
        {/* Tab Navigation */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 sm:mb-8 space-y-4 sm:space-y-0">
          <div className="flex w-full sm:w-auto space-x-2 sm:space-x-4">
            <button
              onClick={() => {
                setActiveTab('list');
                setEditingBlog(null);
              }}
              className={`flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'list'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
              }`}
            >
              <List className="w-5 h-5" />
              <span className="sm:inline">Blog Posts</span>
            </button>
            <button
              onClick={() => {
                setActiveTab('create');
                setEditingBlog(null);
              }}
              className={`flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'create'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
              }`}
            >
              <PenSquare className="w-5 h-5" />
              <span className="sm:inline">Create New</span>
            </button>
          </div>
        </div>

        {/* Blog List View */}
        {activeTab === 'list' && (
          <div className="space-y-4 sm:space-y-6">
            {error && (
              <div className="bg-red-500/20 text-red-400 px-4 py-3 rounded-lg">
                {error}
              </div>
            )}
            {success && (
              <div className="bg-green-500/20 text-green-400 px-4 py-3 rounded-lg">
                {success}
              </div>
            )}

            {/* Search Bar */}
            <div className="relative">
              <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search blogs..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg pl-10 pr-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {isLoading ? (
              <div className="flex justify-center items-center py-12">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
              </div>
            ) : (
              <>
                {/* Desktop View */}
                <div className="hidden sm:block bg-gray-900/50 border border-gray-800 rounded-lg overflow-hidden">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b border-gray-800">
                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Title</th>
                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Category</th>
                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Date</th>
                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Status</th>
                        <th className="px-6 py-4 text-right text-sm font-medium text-gray-300">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredBlogs.map((blog) => (
                        <tr key={blog.id} className="border-b border-gray-800 last:border-0">
                          <td className="px-6 py-4">
                            <div className="flex items-center gap-2">
                              {blog.title}
                              {blog.featured && (
                                <span className="px-2 py-1 text-xs bg-blue-500/20 text-blue-400 rounded-full">
                                  Featured
                                </span>
                              )}
                            </div>
                          </td>
                          <td className="px-6 py-4 text-gray-300">{blog.category}</td>
                          <td className="px-6 py-4 text-gray-300">
                            {new Date(blog.created_at).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4">
                            <span
                              className={`px-2 py-1 text-xs rounded-full ${
                                blog.status === 'Published'
                                  ? 'bg-green-500/20 text-green-400'
                                  : 'bg-yellow-500/20 text-yellow-400'
                              }`}
                            >
                              {blog.status}
                            </span>
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex justify-end gap-2">
                              <button
                                onClick={() => handleEdit(blog)}
                                className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
                              >
                                <Edit className="w-4 h-4 text-blue-400" />
                              </button>
                              <button
                                onClick={() => handleDelete(blog.id)}
                                className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
                              >
                                <Trash2 className="w-4 h-4 text-red-400" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Mobile View */}
                <div className="sm:hidden space-y-4">
                  {filteredBlogs.map((blog) => (
                    <div
                      key={blog.id}
                      className="bg-gray-900/50 border border-gray-800 rounded-lg p-4"
                    >
                      <div className="flex justify-between items-start mb-3">
                        <div className="space-y-1">
                          <h3 className="font-medium">{blog.title}</h3>
                          <p className="text-sm text-gray-400">{blog.category}</p>
                        </div>
                        <BlogActions blog={blog} />
                      </div>
                      
                      <div className="flex flex-wrap gap-2 mt-2">
                        {blog.featured && (
                          <span className="px-2 py-1 text-xs bg-blue-500/20 text-blue-400 rounded-full">
                            Featured
                          </span>
                        )}
                        <span
                          className={`px-2 py-1 text-xs rounded-full ${
                            blog.status === 'Published'
                              ? 'bg-green-500/20 text-green-400'
                              : 'bg-yellow-500/20 text-yellow-400'
                          }`}
                        >
                          {blog.status}
                        </span>
                        <span className="px-2 py-1 text-xs bg-gray-800 text-gray-400 rounded-full">
                          {new Date(blog.created_at).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}

        {/* Create/Edit Blog View */}
        {(activeTab === 'create' || activeTab === 'edit') && (
          <BlogManagement 
            editBlog={editingBlog}
            onSuccess={() => {
              fetchBlogs();
              setActiveTab('list');
            }}
          />
        )}
      </div>
    </div>
  );
};

export default BlogDashboard;