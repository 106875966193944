import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  Search, 
  Calendar, 
  Clock, 
  ArrowRight,
  ArrowLeft
} from 'lucide-react';
import { useHistory } from 'react-router-dom';
import { blogService } from '../dashboard/backendservice/blogService';

const categories = [
  "All",
  "Technology",
  "Case Study",
  "Engineering",
  "Product Updates",
  "Company News"
];

const BlogPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigation = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      setLoading(true);
      const data = await blogService.getBlogs();
      setBlogs(data);
      setError(null);
    } catch (err) {
      setError('Failed to load blog posts');
      console.error('Error loading blogs:', err);
    } finally {
      setLoading(false);
    }
  };

  const filteredBlogs = blogs.filter(blog => {
    const matchesSearch = blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         blog.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === 'All' || blog.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  // First filter out draft posts, then split into featured and regular
  const publishedBlogs = filteredBlogs.filter(blog => blog.status === 'Published');
  const featuredPost = publishedBlogs.find(blog => blog.featured);
  const regularPosts = publishedBlogs.filter(blog => !blog.featured);

  const FeaturedPost = ({ post }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative group cursor-pointer"
      onClick={() => navigation.push(`/blog/${post.id}`)}
    >
      <div className="relative h-[500px] rounded-2xl overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent" />
        {post.image_data ? (
          <img 
            src={post.image_data} 
            alt={post.title}
            className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
          />
        ) : (
          <div className="w-full h-full bg-gray-900" />
        )}
        <div className="absolute inset-0 flex flex-col justify-end p-8">
          <span className="inline-block px-3 py-1 bg-blue-500/20 text-blue-400 rounded-full text-sm mb-4 w-fit">
            {post.category}
          </span>
          <h2 className="text-4xl font-bold mb-4 group-hover:text-blue-400 transition-colors">
            {post.title}
          </h2>
          <p className="text-gray-300 mb-6 line-clamp-2">
            {post.excerpt}
          </p>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <img 
                src="/api/placeholder/40/40"
                alt="Author"
                className="w-10 h-10 rounded-full"
              />
              <div>
                <p className="font-medium">Author Name</p>
                <p className="text-sm text-gray-400">Author Role</p>
              </div>
            </div>
            <div className="flex items-center gap-4 text-sm text-gray-400">
              <span className="flex items-center gap-1">
                <Calendar className="w-4 h-4" />
                {new Date(post.created_at).toLocaleDateString()}
              </span>
              <span className="flex items-center gap-1">
                <Clock className="w-4 h-4" />
                {post.read_time}
              </span>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );

  const BlogCard = ({ post, index }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      className="bg-gray-900/50 backdrop-blur-xl rounded-xl overflow-hidden border border-gray-800 group cursor-pointer hover:border-blue-500/20 transition-colors"
      onClick={() => navigation.push(`/blog/${post.id}`)}
    >
      <div className="relative h-48 overflow-hidden">
        {post.image_data ? (
          <img 
            src={post.image_data} 
            alt={post.title}
            className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
          />
        ) : (
          <div className="w-full h-full bg-gray-900" />
        )}
      </div>
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <span className="px-3 py-1 bg-blue-500/20 text-blue-400 rounded-full text-sm">
            {post.category}
          </span>
          <div className="flex items-center gap-2 text-sm text-gray-400">
            <Clock className="w-4 h-4" />
            {post.read_time}
          </div>
        </div>
        <h3 className="text-xl font-bold mb-3 group-hover:text-blue-400 transition-colors">
          {post.title}
        </h3>
        <p className="text-gray-400 mb-4 line-clamp-2">
          {post.excerpt}
        </p>
        <div className="flex items-center gap-2 mb-4">
          {post.tags?.map((tag, i) => (
            <span 
              key={i}
              className="px-2 py-1 bg-gray-800 rounded-full text-xs text-gray-300"
            >
              {tag}
            </span>
          ))}
        </div>
        <div className="flex items-center justify-between pt-4 border-t border-gray-800">
          <div className="flex items-center gap-3">
            <img 
              src="/api/placeholder/40/40"
              alt="Author"
              className="w-8 h-8 rounded-full"
            />
            <div>
              <p className="text-sm font-medium">Author Name</p>
              <p className="text-xs text-gray-400">Author Role</p>
            </div>
          </div>
          <span className="text-blue-400 flex items-center gap-1 text-sm">
            Read more <ArrowRight className="w-4 h-4" />
          </span>
        </div>
      </div>
    </motion.div>
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center">
        <p className="text-red-400 mb-4">{error}</p>
        <button 
          onClick={fetchBlogs}
          className="px-4 py-2 bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white pb-20">

      {/* Header */}
      <div className="h-[40vh] relative flex items-center justify-center overflow-hidden pt-16">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-900/20 via-black to-black" />
        <div className="absolute top-20 left-20 w-64 h-64 bg-blue-500/10 rounded-full blur-3xl" />
        <div className="absolute bottom-0 right-20 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl" />
        <div className="relative z-10 text-center">
          <h1 className="text-5xl font-bold mb-4 hero-title">Phynux Blog</h1>
          <p className="text-xl text-gray-300">
            Insights, updates, and stories from our team
          </p>
        </div>
      </div>

      <motion.button
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        className="absolute top-8 left-8 text-gray-400 hover:text-white flex items-center gap-2 transition-colors"
        onClick={() => navigation.push('/')}
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Home
      </motion.button>

      {/* Search and Filter Bar */}
      <div className={'sticky top-0 z-10 bg-black/80 backdrop-blur-xl border-b border-gray-800 transition-all'}>
        <div className="container mx-auto px-6 py-4">
          <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
            <div className="relative flex-1 max-w-xl w-full">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search articles..."
                className="w-full bg-gray-900/50 border border-gray-800 rounded-lg pl-10 pr-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <div className="flex items-center gap-2 overflow-x-auto pb-2 md:pb-0 w-full md:w-auto">
              {categories.map((category) => (
                <button
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                  className={`px-4 py-2 rounded-lg whitespace-nowrap transition-colors ${
                    selectedCategory === category
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-900/50 text-gray-400 hover:text-white'
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="container mx-auto px-6 mt-12">
        {/* Featured Post */}
        {featuredPost && (
          <div className="mb-20">
            <h2 className="text-2xl font-bold mb-8">Featured Story</h2>
            <FeaturedPost post={featuredPost} />
          </div>
        )}

        {/* Latest Posts */}
        <div>
          <h2 className="text-2xl font-bold mb-8">Latest Stories</h2>
          {regularPosts.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {regularPosts.map((post, index) => (
                <BlogCard key={post.id} post={post} index={index} />
              ))}
            </div>
          ) : (
            <div className="text-center py-20 text-gray-400">
              No blog posts found matching your criteria
            </div>
          )}
        </div>

        {/* Load More */}
        {regularPosts.length > 0 && (
          <div className="text-center mt-12">
            <button className="px-6 py-3 bg-gray-900/50 border border-gray-800 rounded-lg hover:bg-gray-800 transition-colors">
              Load More Articles
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPage;