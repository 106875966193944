import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Calendar,
  Clock,
  ChevronLeft,
  Share2,
  BookmarkPlus,
  MessageCircle,
  Facebook,
  Twitter,
  Linkedin,
  Copy,
  ArrowLeft
} from 'lucide-react';
import { blogService } from '../../dashboard/backendservice/blogService'

const BlogReader = () => {
  const { id } = useParams();
  const history = useHistory();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showShareTooltip, setShowShareTooltip] = useState(false);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const data = await blogService.getBlog(id);
        setBlog(data);
        if(data.status !='Published'){
          setError('Failed to load blog post');
        }
      } catch (err) {
        setError('Failed to load blog post');
        console.error('Error loading blog:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  const handleShare = async (platform) => {
    const url = window.location.href;

    switch (platform) {
      case 'copy':
        await navigator.clipboard.writeText(url);
        setShowShareTooltip(true);
        setTimeout(() => setShowShareTooltip(false), 2000);
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${url}&text=${blog.title}`, '_blank');
        break;
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, '_blank');
        break;
      default:
        break;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error || !blog) {
    return (
      <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center">
        <p className="text-red-400 mb-4">{error || 'Blog post not found'}</p>
        <button
          onClick={() => history.push('/blog')}
          className="px-4 py-2 bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
        >
          Back to Blog List
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Back Button */}
      <button
        onClick={() => history.push('/blog')}
        className="fixed top-8 left-8 z-50 flex items-center gap-2 px-4 py-2 bg-gray-900/80 backdrop-blur-lg rounded-lg hover:bg-gray-800 transition-colors"
      >
        <ArrowLeft className="w-5 h-5" />
        <span className="hidden sm:inline">Back to Blog</span>
      </button>

      {/* Share Menu */}
      <div className="fixed left-8 top-1/2 -translate-y-1/2 hidden lg:flex flex-col gap-4 z-50">
        <div className="relative">
          {showShareTooltip && (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              className="absolute left-14 top-1/2 -translate-y-1/2 bg-green-500/20 text-green-400 px-3 py-1 rounded-lg whitespace-nowrap"
            >
              Link copied!
            </motion.div>
          )}
          <button
            onClick={() => handleShare('copy')}
            className="p-3 bg-gray-900/80 backdrop-blur-lg rounded-lg hover:bg-gray-800 transition-colors"
          >
            <Copy className="w-5 h-5" />
          </button>
        </div>
        <button
          onClick={() => handleShare('twitter')}
          className="p-3 bg-gray-900/80 backdrop-blur-lg rounded-lg hover:bg-gray-800 transition-colors"
        >
          <Twitter className="w-5 h-5" />
        </button>
        <button
          onClick={() => handleShare('facebook')}
          className="p-3 bg-gray-900/80 backdrop-blur-lg rounded-lg hover:bg-gray-800 transition-colors"
        >
          <Facebook className="w-5 h-5" />
        </button>
        <button
          onClick={() => handleShare('linkedin')}
          className="p-3 bg-gray-900/80 backdrop-blur-lg rounded-lg hover:bg-gray-800 transition-colors"
        >
          <Linkedin className="w-5 h-5" />
        </button>
      </div>

      {/* Header Image with Parallax Effect */}
      <div className="h-[70vh] relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/50 to-black" />
        <motion.div
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute'
          }}
        >
          {blog.image_data ? (
            <img
              src={blog.image_data}
              alt={blog.title}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-gradient-to-br from-blue-900/20 to-purple-900/20" />
          )}
        </motion.div>
      </div>

      {/* Content */}
      <div className="relative -mt-32">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="max-w-4xl mx-auto"
          >
            {/* Article Header */}
            <div className="bg-gray-900/70 backdrop-blur-xl rounded-2xl p-8 border border-gray-800 mb-12">
              {/* Category and Metadata */}
              <div className="flex flex-wrap items-center gap-4 mb-6">
                <span className="px-3 py-1 bg-blue-500/20 text-blue-400 rounded-full text-sm">
                  {blog.category}
                </span>
                <span className="flex items-center gap-2 text-sm text-gray-400">
                  <Calendar className="w-4 h-4" />
                  {new Date(blog.created_at).toLocaleDateString()}
                </span>
                <span className="flex items-center gap-2 text-sm text-gray-400">
                  <Clock className="w-4 h-4" />
                  {blog.read_time}
                </span>
              </div>

              {/* Title */}
              <h1 className="text-4xl sm:text-5xl font-bold mb-6 leading-tight">
                {blog.title}
              </h1>

              {/* Author */}
              <div className="flex items-center gap-4">
                <img
                  src="/api/placeholder/40/40"
                  alt="Author"
                  className="w-12 h-12 rounded-full border-2 border-blue-500/20"
                />
                <div>
                  <p className="font-medium">Author Name</p>
                  <p className="text-sm text-gray-400">Author Role</p>
                </div>
              </div>
            </div>

            {/* Article Content */}
            <div className="prose prose-invert prose-lg max-w-none mb-12">
              {/* Excerpt */}
              <div className="text-xl text-blue-400 mb-8 italic border-l-4 border-blue-500 pl-6">
                {blog.excerpt}
              </div>

              {/* Main Content */}
              <div className="space-y-6 leading-relaxed">
                    <div className="text-gray-300 whitespace-pre-wrap">
                    {blog.content}
                    </div>
                </div>
            </div>

            {/* Tags */}
            <div className="border-t border-gray-800 pt-8 pb-20">
              <div className="flex flex-wrap gap-2">
                {blog.tags?.map((tag, index) => (
                  <span
                    key={index}
                    className="px-4 py-2 bg-gray-900/50 border border-gray-800 rounded-lg text-sm text-gray-300"
                  >
                    #{tag}
                  </span>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Mobile Share Menu */}
      <div className="fixed bottom-0 left-0 right-0 lg:hidden bg-gray-900/80 backdrop-blur-xl border-t border-gray-800">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-around">
            <button
              onClick={() => handleShare('copy')}
              className="p-3 hover:bg-gray-800 rounded-lg transition-colors relative"
            >
              <Copy className="w-5 h-5" />
              {showShareTooltip && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 bg-green-500/20 text-green-400 px-3 py-1 rounded-lg whitespace-nowrap"
                >
                  Link copied!
                </motion.div>
              )}
            </button>
            <button
              onClick={() => handleShare('twitter')}
              className="p-3 hover:bg-gray-800 rounded-lg transition-colors"
            >
              <Twitter className="w-5 h-5" />
            </button>
            <button
              onClick={() => handleShare('facebook')}
              className="p-3 hover:bg-gray-800 rounded-lg transition-colors"
            >
              <Facebook className="w-5 h-5" />
            </button>
            <button
              onClick={() => handleShare('linkedin')}
              className="p-3 hover:bg-gray-800 rounded-lg transition-colors"
            >
              <Linkedin className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogReader;