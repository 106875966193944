import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useScroll, useSpring } from 'framer-motion';
import { 
  X, Briefcase, MapPin, DollarSign, Clock, 
  ArrowRight, ChevronDown, Globe, Users, Zap,
  Sparkles, Shield, Rocket, Upload, Check, ArrowLeft
} from 'lucide-react';

import { jobsService } from './backendservice/jobService';
import { useHistory } from 'react-router-dom';

const mockJobs = [
    {
      id: 1,
      title: "Senior Sales Representative",
      location: "Loveland, OH",
      type: "Full-time",
      salary: "Base salary: $72k–$81k + Commission",
      description: "We're looking for a Senior Sales Representative to join our team...",
      requirements: [
        "5+ years of proven sales experience with a track record of exceeding targets.",
        "Strong client relationship management and communication skills.",
        "Ability to develop and execute strategic sales plans.",
    ]
    },
    {
      id: 2,
      title: "Juinor Sales Representative",
      location: "Remote",
      type: "Part-time",
      salary: "Commission Based Pay",
      description: "Join our sales team to develop your sales experience ",
      requirements: [
        "1-2 years of sales experience or a strong interest in sales.",
        "Excellent communication and interpersonal skills.",
        "Willingness to learn and adapt in a fast-paced environment."
    ]
    }
  ];


  const ApplicationForm = ({ job, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [formData, setFormData] = useState({
      fullName: '',
      email: '',
      phone: '',
      resume: null,
      coverLetter: '',
      linkedIn: '',
      portfolio: '',
      experience: '',
    });

    const [currentStep, setCurrentStep] = useState(1);
    const [selectedFileName, setSelectedFileName] = useState('');
    const totalSteps = 3;

    const handleFileSelect = (e) => {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          alert('File size must be less than 5MB');
          return;
        }
        setFormData({...formData, resume: file});
        setSelectedFileName(file.name);
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setError(null);
      setLoading(true);

      try {
        await jobsService.submitApplication(job.id, formData);
        setSuccess(true);
        setTimeout(() => {
          onClose();
        }, 2000);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const validateStep = (step) => {
      switch (step) {
        case 1:
          return formData.fullName && formData.email && formData.phone;
        case 2:
          return formData.experience && formData.resume;
        case 3:
          return true;
        default:
          return false;
      }
    };

    const handleNextStep = (e) => {
      e.preventDefault(); // Prevent form submission
      if (currentStep < totalSteps && canProceed) {
        setCurrentStep(step => step + 1);
      }
    };

    const canProceed = validateStep(currentStep);
  
    const renderStepContent = () => {
      switch (currentStep) {
        case 1:
          return (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              className="space-y-6"
            >
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-slate-300">Full Name *</label>
                  <input
                    type="text"
                    required
                    value={formData.fullName}
                    onChange={e => setFormData({...formData, fullName: e.target.value})}
                    className="w-full bg-slate-800/50 border border-slate-700 focus:border-indigo-500 rounded-lg px-4 py-3 text-white placeholder-slate-400 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                    placeholder="John Doe"
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-slate-300">Email *</label>
                  <input
                    type="email"
                    required
                    value={formData.email}
                    onChange={e => setFormData({...formData, email: e.target.value})}
                    className="w-full bg-slate-800/50 border border-slate-700 focus:border-indigo-500 rounded-lg px-4 py-3 text-white placeholder-slate-400 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                    placeholder="your@email.com"
                  />
                </div>
              </div>
  
              <div className="space-y-2">
                <label className="block text-sm font-medium text-slate-300">Phone Number *</label>
                <input
                  type="tel"
                  required
                  value={formData.phone}
                  onChange={e => setFormData({...formData, phone: e.target.value})}
                  className="w-full bg-slate-800/50 border border-slate-700 focus:border-indigo-500 rounded-lg px-4 py-3 text-white placeholder-slate-400 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                  placeholder="+1 (555) 000-0000"
                />
              </div>
  
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-slate-300">LinkedIn Profile</label>
                  <input
                    type="url"
                    value={formData.linkedIn}
                    onChange={e => setFormData({...formData, linkedIn: e.target.value})}
                    className="w-full bg-slate-800/50 border border-slate-700 focus:border-indigo-500 rounded-lg px-4 py-3 text-white placeholder-slate-400 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                    placeholder="https://linkedin.com/in/..."
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-slate-300">Portfolio URL</label>
                  <input
                    type="url"
                    value={formData.portfolio}
                    onChange={e => setFormData({...formData, portfolio: e.target.value})}
                    className="w-full bg-slate-800/50 border border-slate-700 focus:border-indigo-500 rounded-lg px-4 py-3 text-white placeholder-slate-400 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                    placeholder="https://..."
                  />
                </div>
              </div>
            </motion.div>
          );
        case 2:
          return (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              className="space-y-6"
            >
              <div className="space-y-2">
                <label className="block text-sm font-medium text-slate-300">Years of Experience *</label>
                <select
                  value={formData.experience}
                  onChange={e => setFormData({...formData, experience: e.target.value})}
                  className="w-full bg-slate-800/50 border border-slate-700 focus:border-indigo-500 rounded-lg px-4 py-3 text-white placeholder-slate-400 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                >
                  <option value="">Select experience</option>
                  <option value="0-1">0-1 years</option>
                  <option value="1-3">1-3 years</option>
                  <option value="3-5">3-5 years</option>
                  <option value="5+">5+ years</option>
                </select>
              </div>
  
              <div className="space-y-2">
                <label className="block text-sm font-medium text-slate-300 mb-2">Resume *</label>
                <div className="relative">
                  <input
                    type="file"
                    required
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileSelect}
                    className="hidden"
                    id="resume-upload"
                  />
                  <label
                    htmlFor="resume-upload"
                    className="flex items-center justify-center w-full bg-slate-800/50 border-2 border-dashed border-slate-700 hover:border-indigo-500 rounded-lg px-4 py-6 cursor-pointer group transition-all"
                  >
                    <div className="text-center">
                      <Upload className="w-6 h-6 mx-auto mb-2 text-slate-400 group-hover:text-indigo-400" />
                      <span className="text-sm text-slate-400 group-hover:text-indigo-400">
                        {selectedFileName ? 'Change file' : 'Drop your resume here or click to browse'}
                      </span>
                      <span className="block text-xs text-slate-500 mt-1">
                        PDF, DOC, DOCX (Max 5MB)
                      </span>
                    </div>
                  </label>
                  
                  {/* File Preview */}
                  {selectedFileName && (
                    <div className="mt-3 p-3 bg-slate-800/30 rounded-lg border border-slate-700">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <Check className="w-4 h-4 text-green-400" />
                          <span className="text-sm text-slate-300 truncate">
                            {selectedFileName}
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedFileName('');
                            setFormData({...formData, resume: null});
                          }}
                          className="p-1 hover:bg-slate-700/50 rounded"
                        >
                          <X className="w-4 h-4 text-slate-400" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
  
              <div className="space-y-2">
                <label className="block text-sm font-medium text-slate-300">Cover Letter</label>
                <textarea
                  rows="4"
                  value={formData.coverLetter}
                  onChange={e => setFormData({...formData, coverLetter: e.target.value})}
                  className="w-full bg-slate-800/50 border border-slate-700 focus:border-indigo-500 rounded-lg px-4 py-3 text-white placeholder-slate-400 focus:ring-2 focus:ring-indigo-500/20 transition-all resize-none"
                  placeholder="Tell us why you're interested in this position..."
                />
              </div>
            </motion.div>
          );
        case 3:
          return (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              className="space-y-6"
            >
              <div className="bg-slate-800/50 rounded-lg p-6 border border-slate-700">
                <h4 className="text-lg font-medium text-white mb-4">Review Your Application</h4>
                <dl className="space-y-4">
                  {Object.entries({
                    'Full Name': formData.fullName,
                    'Email': formData.email,
                    'Phone': formData.phone,
                    'Experience': formData.experience,
                    'Resume': selectedFileName,
                    'LinkedIn': formData.linkedIn,
                    'Portfolio': formData.portfolio,
                  }).map(([key, value]) => value && (
                    <div key={key} className="grid grid-cols-1 md:grid-cols-3 gap-2">
                      <dt className="text-sm font-medium text-slate-400">{key}:</dt>
                      <dd className="text-sm text-white md:col-span-2 break-all">{value}</dd>
                    </div>
                  ))}
                </dl>
              </div>
  
              <div className="bg-indigo-900/20 rounded-lg p-4 border border-indigo-500/20">
                <p className="text-sm text-indigo-300">
                  By submitting this application, you confirm that all information provided is accurate and complete.
                </p>
              </div>
            </motion.div>
          );
        default:
          return null;
      }
    };
  
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-start justify-center p-4 bg-slate-950/80 backdrop-blur-sm overflow-y-auto"
      >
        <motion.div
          initial={{ scale: 0.95 }}
          animate={{ scale: 1 }}
          className="bg-slate-900 rounded-2xl shadow-xl w-full max-w-3xl my-8"
          onClick={e => e.stopPropagation()}
        >
          {/* Header */}
          <div className="p-4 sm:p-6 border-b border-slate-800">
            <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-4">
              <div>
                <h2 className="text-xl sm:text-2xl font-bold text-white mb-1">{job.title}</h2>
                <p className="text-slate-400">{job.location}</p>
              </div>
              <button 
                onClick={onClose}
                className="self-end sm:self-start p-2 hover:bg-slate-800 rounded-lg transition-colors"
              >
                <X className="w-5 h-5 text-slate-400" />
              </button>
            </div>
  
            {/* Progress bar */}
            <div className="mt-6 mb-2">
              <div className="flex justify-between text-sm text-slate-400 mb-2">
                <span>Step {currentStep} of {totalSteps}</span>
                <span>{Math.round((currentStep / totalSteps) * 100)}% Complete</span>
              </div>
              <div className="h-2 bg-slate-800 rounded-full">
                <motion.div
                  className="h-full bg-indigo-500 rounded-full"
                  initial={{ width: 0 }}
                  animate={{ width: `${(currentStep / totalSteps) * 100}%` }}
                  transition={{ duration: 0.3 }}
                />
              </div>
            </div>
          </div>
  
          {/* Form content */}
          <form onSubmit={handleSubmit} className="p-4 sm:p-6">
          {error && (
            <div className="mb-6 p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
              {error}
            </div>
          )}

          {success && (
            <div className="mb-6 p-4 bg-green-500/10 border border-green-500/20 rounded-lg text-green-400">
              Application submitted successfully!
            </div>
          )}

          <AnimatePresence mode="wait">
            {renderStepContent()}
          </AnimatePresence>

          <div className="flex justify-between mt-8 pt-6 border-t border-slate-800">
            {currentStep > 1 ? (
              <button
                type="button"
                onClick={() => setCurrentStep(step => step - 1)}
                className="px-4 py-2 text-slate-400 hover:text-white transition-colors"
              >
                Back
              </button>
            ) : (
              <div />
            )}

        {currentStep < totalSteps ? (
          <button
            type="button"
            onClick={handleNextStep}
            disabled={!canProceed}
            className="px-6 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Continue
          </button>
          ) : (
          <button
            type="submit"
            disabled={loading || success || !canProceed}
            className="inline-flex items-center px-6 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <>
                <span className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                Submitting...
              </>
            ) : (
              'Submit Application'
            )}
          </button>
        )}
          </div>
        </form>
        </motion.div>
      </motion.div>
    );
  };

const ScrollProgress = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  return (
    <motion.div
      className="fixed top-0 left-0 right-0 h-0.5 bg-indigo-500 origin-left z-50"
      style={{ scaleX }}
    />
  );
};

const HeroSection = () => (
  <div className="relative h-screen flex items-center justify-center overflow-hidden bg-[#0A0A0F]">
    {/* Animated background gradient */}
    <div className="absolute inset-0 bg-gradient-to-br from-indigo-950/30 via-slate-950 to-slate-950" />
    
    {/* Decorative elements */}
    <div className="absolute inset-0">
      <div className="absolute top-20 left-20 w-64 h-64 bg-indigo-500/10 rounded-full blur-3xl" />
      <div className="absolute bottom-20 right-20 w-96 h-96 bg-blue-500/10 rounded-full blur-3xl" />
    </div>
    
    <motion.div 
      className="relative text-center max-w-4xl mx-auto px-4 z-10"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
    <div className="flex flex-col md:flex-row items-center justify-center text-center mb-8 space-y-4 md:space-y-0">
        <img src={require('assets/images/phynux_logo.png')} className="w-8 h-8 rounded-full" alt="Profile" />
        <span className="text-2xl font-bold text-white md:ml-4">Phynux</span>
        
    </div>

      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.2 }}
        className="flex justify-center mb-6"
      >
        <span className="px-4 py-2 rounded-full bg-indigo-500/10 text-indigo-300 text-sm font-medium border border-indigo-500/20">
          We're Hiring!
        </span>
      </motion.div>

      <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-gradient-to-b from-white via-white to-indigo-200 bg-clip-text text-transparent leading-tight">
        Shape the Future of Technology
      </h1>
      <p className="text-xl md:text-2xl text-slate-400 mb-8 leading-relaxed">
        Join a team of innovators, dreamers, and builders creating tomorrow's solutions today.
      </p>
      <motion.div
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <button 
          onClick={() => document.getElementById('openPositions').scrollIntoView({ behavior: 'smooth' })}
          className="group px-8 py-4 bg-gradient-to-r from-indigo-600 to-indigo-500 rounded-lg text-lg font-medium hover:from-indigo-500 hover:to-indigo-600 transition-all shadow-lg hover:shadow-indigo-500/25"
        >
          Explore Opportunities
          <ArrowRight className="inline ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
        </button>
      </motion.div>
    </motion.div>

    <motion.div 
      className="absolute bottom-10 left-0 right-0 flex justify-center"
      animate={{ y: [0, 10, 0] }}
      transition={{ duration: 2, repeat: Infinity }}
    >
      <ChevronDown className="w-6 h-6 text-slate-500" />
    </motion.div>
  </div>
);

const BenefitsSection = () => {
  const benefits = [
    {
      icon: <Rocket className="w-6 h-6" />,
      title: "Innovation First",
      description: "Work on cutting-edge technology that shapes the future."
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Remote-First Culture",
      description: "Freedom to work from anywhere with our global team."
    },
    {
      icon: <Sparkles className="w-6 h-6" />,
      title: "Growth & Learning",
      description: "Continuous development with learning stipends and mentorship."
    }
  ];

  return (
    <div className="py-24 bg-gradient-to-b from-[#0A0A0F] to-slate-950">
      <div className="max-w-6xl mx-auto px-4">
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <h2 className="text-3xl md:text-4xl font-bold mb-4 bg-gradient-to-r from-white to-slate-300 bg-clip-text text-transparent">
            Why Choose Us?
          </h2>
          <p className="text-lg text-slate-400 max-w-2xl mx-auto">
            Join a workplace that values innovation, growth, and well-being.
          </p>
        </motion.div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              className="p-6 rounded-xl bg-slate-900/50 border border-slate-800/50 backdrop-blur-sm hover:border-indigo-500/20 transition-all"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
            >
              <div className="inline-flex p-3 rounded-lg bg-indigo-500/10 text-indigo-400 mb-4">
                {benefit.icon}
              </div>
              <h3 className="text-xl font-semibold mb-3 text-white">{benefit.title}</h3>
              <p className="text-slate-400 leading-relaxed">{benefit.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

const JobCard = ({ job, onApply }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    whileHover={{ y: -2 }}
    className="group bg-slate-900/50 backdrop-blur-xl rounded-xl p-6 border border-slate-800/50 hover:border-indigo-500/20 transition-all cursor-pointer"
    onClick={() => onApply(job)}
  >
    <div className="flex justify-between items-start mb-4">
      <div>
        <h3 className="text-xl font-semibold text-white group-hover:text-indigo-400 transition-colors">
          {job.title}
        </h3>
        <p className="text-slate-400 mt-1">{job.location}</p>
      </div>
      <span className="px-3 py-1 bg-indigo-500/10 text-indigo-400 rounded-full text-sm font-medium">
        {job.type}
      </span>
    </div>

    <div className="space-y-3 text-slate-400">
      <div className="flex items-center">
        <DollarSign className="w-4 h-4 mr-2 text-slate-500" />
        <span>{job.salary}</span>
      </div>
    </div>

    <p className="mt-4 text-slate-400 leading-relaxed">{job.description}</p>

    <div className="mt-6 space-y-4">
      <div className="flex flex-wrap gap-2">
        {job.requirements.slice(0, 3).map((req, index) => (
          <span key={index} className="px-3 py-1 bg-slate-800/50 rounded-full text-sm text-slate-300">
            {req}
          </span>
        ))}
      </div>
      
      <div className="flex justify-between items-center pt-4 border-t border-slate-800/50">
        <span className="text-slate-400 text-sm">Posted 2 days ago</span>
        <motion.span 
          className="text-indigo-400 flex items-center"
          whileHover={{ x: 5 }}
        >
          View Details
          <ArrowRight className="ml-2 w-4 h-4" />
        </motion.span>
      </div>
    </div>
  </motion.div>
);

const CareersPage = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigation = useHistory().push

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setLoading(true);
        const data = await jobsService.getJobs();
        setJobs(data);
      } catch (err) {
        setError('Failed to load job listings');
        console.error('Error loading jobs:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  return (
    <div className="min-h-screen bg-[#0A0A0F]">
      <ScrollProgress />
      
      <HeroSection />
      
      <BenefitsSection />

      <div id="openPositions" className="max-w-6xl mx-auto px-4 py-24">
      <motion.button
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        className="absolute top-8 left-8 text-gray-400 hover:text-white flex items-center gap-2 transition-colors"
        onClick={() => navigation('/')}
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Home
      </motion.button>
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <h2 className="text-3xl md:text-4xl font-bold mb-4 bg-gradient-to-r from-white to-slate-300 bg-clip-text text-transparent">
            Open Positions
          </h2>
          <p className="text-lg text-slate-400 max-w-2xl mx-auto">
            Find your next role and join our mission to innovate
          </p>
        </motion.div>

        {loading ? (
          <div className="flex justify-center items-center py-20">
            <div className="w-8 h-8 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin" />
          </div>
        ) : error ? (
          <div className="text-center py-20 text-slate-400">
            {error}
          </div>
        ) : jobs.length === 0 ? (
          <div className="text-center py-20 text-slate-400">
            No open positions at the moment. Please check back later!
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-6">
            {jobs.map((job) => (
              <>
              {job['status'] == "Active" && (
                <JobCard
                  key={job.id}
                  job={job}
                  onApply={setSelectedJob}
                />
              )}
              </>
            ))}
          </div>
        )}
      </div>

      <AnimatePresence>
        {selectedJob && (
          <ApplicationForm
            job={selectedJob}
            onClose={() => setSelectedJob(null)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default CareersPage;